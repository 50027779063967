import { isFeatureEnabled, isFeatureEnabledForUser } from "../../utils";

export const IDENTITY_TYPE_PLACEHOLDER = "%IDENTITY_TYPE%";
export const USER_NAME_PLACEHOLDER = "%USER_NAME%";

type PermissionType = "all" | "any";

export interface IMenuItem {
    dataTestId?: string;
    experimentalFeature?: boolean;
    path?: string;
    permissions?: string[];
    permissionType?: PermissionType;
    subMenu?: ISubMenuItem[];
    text: string;
}

export interface ISubMenuItem {
    experimentalFeature?: boolean;
    divider?: "menuDividerBottom" | "menuDividerTop";
    path?: string;
    permissions?: string[];
    permissionType?: PermissionType;
    text: string;
}

export const navsData = (): IMenuItem[] => [
    {
        text: IDENTITY_TYPE_PLACEHOLDER,
        permissions: ["POLICY_READ", "CLAIM_READ", "POLICY_HOLDER_READ"],
        subMenu: [
            {
                text: "quotes",
                permissions: ["POLICY_READ", "POLICY_HOLDER_READ"],
                permissionType: "all",
                path: "/quotes",
            },
            {
                text: "referrals",
                permissions: ["POLICY_READ", "POLICY_HOLDER_READ"],
                permissionType: "all",
                path: "/referrals",
            },
            {
                text: "policies",
                permissions: ["POLICY_READ", "POLICY_HOLDER_READ"],
                permissionType: "all",
                path: "/policies",
            },
            {
                text: "dtqs",
                permissions: ["POLICY_READ", "POLICY_HOLDER_READ"],
                permissionType: "all",
                path: "/dtqs",
            },
            {
                text: "cancellations",
                permissions: ["POLICY_READ", "POLICY_HOLDER_READ"],
                permissionType: "all",
                path: "/cancellations",
            },
            {
                text: "claims",
                permissions: ["CLAIM_READ", "POLICY_HOLDER_READ"],
                permissionType: "all",
                path: "/claims",
            },
        ],
    },
    {
        text: "finance",
        permissions: ["SETTLEMENT_EDIT"],
        subMenu: [
            {
                text: "settlements",
                permissions: ["SETTLEMENT_EDIT"],
                path: "/settlements",
            },
            {
                text: "drawdowns",
                permissions: ["SETTLEMENT_EDIT"],
                path: "/statements",
            },
            {
                text: "overdueCollections",
                permissions: ["SETTLEMENT_EDIT"],
                path: "/overdue-premiums",
            },
        ],
    },
    {
        experimentalFeature: !isFeatureEnabled("menuTools"),
        text: "tools",
        permissions: ["IMPORT_TOOL", "PRODUCT_AUTHORIZE", "PRODUCT_EDIT", "AUDIT_EVENT_READ"],
        subMenu: [
            {
                text: "sureByld",
                permissions: ["PRODUCT_AUTHORIZE", "PRODUCT_EDIT"],
                path: "/surebyld",
            },
            {
                experimentalFeature: !isFeatureEnabledForUser("menuCSVImporter"),
                divider: "menuDividerTop",
                text: "importFromCSVFile",
                permissions: ["IMPORT_TOOL"],
                path: "/import",
            },
            {
                experimentalFeature: !isFeatureEnabledForUser("menuCSVImporter"),
                divider: "menuDividerBottom",
                text: "checkImportStatus",
                permissions: ["IMPORT_TOOL"],
                path: "/import-status",
            },
            {
                text: "auditEvents",
                permissions: ["AUDIT_EVENT_READ"],
                path: "/audit-events",
            },
        ],
    },
    {
        experimentalFeature: !isFeatureEnabled("menuReports"),
        permissions: ["POLICY_READ"],
        text: "reports",
        path: "/reports",
        subMenu: [
            {
                text: "cancellations",
                permissions: ["POLICY_READ"],
                path: "/reports/cancellations",
            },
            {
                text: "newBusiness",
                permissions: ["POLICY_READ"],
                path: "/reports/new-business",
            },
            {
                text: "policyholder",
                permissions: ["POLICY_READ"],
                path: "/reports/policyholders",
            },
            {
                text: "renewals",
                permissions: ["POLICY_READ"],
                path: "/reports/renewals",
            },
        ],
    },
    {
        experimentalFeature: !isFeatureEnabled("menuReportsOld"),
        text: "reports",
        permissions: ["POLICY_READ"],
        path: "/reports",
    },
    {
        text: "admin",
        permissions: ["USER_EDIT", "ROLE_EDIT", "SETTLEMENT_ACCOUNT_READ", "BRAND_EDIT"],
        subMenu: [
            {
                text: "userAdministration",
                permissions: ["USER_EDIT"],
                path: "/user-administration",
            },
            {
                text: "roleAdministration",
                permissions: ["ROLE_EDIT"],
                path: "/role-administration",
            },
            {
                text: "paymentGateway",
                permissions: ["SETTLEMENT_ACCOUNT_READ"],
                path: "/payment-gateway",
            },
            {
                text: "logo",
                permissions: ["BRAND_EDIT"],
                path: "/company-logo",
            },
        ],
    },
];

export const navProfileData = {
    text: USER_NAME_PLACEHOLDER,
    subMenu: [
        {
            text: "accountSettings",
            path: "/profile",
        },
        {
            text: "support",
            path: "/support",
        },
        {
            text: "aboutSurelync",
            path: "/about",
        },
        {
            text: "signOut",
            path: "/login",
        },
    ],
};
