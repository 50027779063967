import React from "react";
import { Box, Button, createGenerateClassName, Grid, Hidden, IconButton, InputAdornment, StylesProvider, TextField } from "@material-ui/core";
import { BuildSharp, GetApp, Search as SearchIcon } from "@material-ui/icons";
import { Theme, ThemeProvider } from "@material-ui/core/styles";
import { useStyles } from "./styles";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "table-option",
});

interface IProps {
    customiseLabel: string;
    downloadLabel: string;
    searchLabel: string;
    theme: Theme;
    customiseClick: () => void;
    downloadClick: () => void;
    searchChange: (value: string) => void;
}

const TableOptions: React.FC<IProps> = ({ customiseLabel, downloadLabel, searchLabel, theme, customiseClick, downloadClick, searchChange }) => {
    const classes = useStyles(theme)();

    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <Grid container direction="row" spacing={2} justify="flex-end">
                    <Hidden smDown={true}>
                        <Grid item>
                            <TextField
                                label={searchLabel}
                                variant="filled"
                                fullWidth
                                onChange={(event) => searchChange(event.target.value)}
                                inputProps={{
                                    "data-testid": "input-text",
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="search button">
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true
                                }}
                            />
                        </Grid>
                    </Hidden>
                    <Grid item>
                        <Button
                            className={classes.buttonFullHeight}
                            variant="text"
                            color="primary"
                            startIcon={<GetApp />}
                            onClick={downloadClick}
                            fullWidth
                        >
                            {downloadLabel}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            className={classes.buttonFullHeight}
                            variant="text"
                            color="primary"
                            startIcon={<BuildSharp />}
                            onClick={customiseClick}
                            fullWidth
                        >
                            {customiseLabel}
                        </Button>
                    </Grid>
                </Grid>
                <Box display="flex"></Box>
            </ThemeProvider>
        </StylesProvider>
    );
};

export default TableOptions;
