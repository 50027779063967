import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, StylesProvider, ThemeProvider, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { createGenerateClassName, Theme } from "@material-ui/core/styles";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "inputcustom",
});

export interface InfoDialogProps {
    isError?: boolean;
    open: boolean;
    message: string | string[];
    title?: string;
    theme: Theme;
    onClose: () => void;
}

export const InfoDialog: React.FC<InfoDialogProps> = ({ isError, open, message, theme, title, onClose }) => {
    const { t } = useTranslation();
    const data = Array.isArray(message) ? message : [message];

    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <Dialog maxWidth="xs" aria-labelledby="info-dialog-title" open={open} onClose={onClose}>
                    {title ? <DialogTitle id="info-dialog-title">{title}</DialogTitle> : null}
                    <DialogContent dividers>
                        <Box m={3}>
                            {data.map((message, index) => (
                                <Typography color={isError ? "error" : "textPrimary"} gutterBottom key={index} variant="body1">
                                    {message}
                                </Typography>
                            ))}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={onClose} color="primary" variant="contained">
                            {t("ok")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </StylesProvider>
    );
};
