import {IAttachment} from "./attachment.model";
import {IAddress} from "./address.model";
import {ITransaction} from "./transaction.model";
import {IIssues} from "./referral.mode";

export interface IAttempts {
    date: string;
    message: string;
    reference?: string;
    status: string;
}

interface IAmount {
    cumulative: number;
    delta: number;
}

export interface IBrokerage {
    "Broker Platform Fee"?: IAmount;
    "Net Brokerage"?: IAmount;
    "Net Premium"?: IAmount;
    "Net Net Premium"?: IAmount;
    "Insurer Platform Fee"?: IAmount;
    IPT?: IAmount;
    Brokerage?: IAmount;
    "Platform Fee"?: IAmount;
    "MGA Commission"?: IAmount;
    "Net MGA Commission"?: IAmount;
}

interface ICashMovements {
    administrationCumulative: number;
    administrationToDate: number;
    cumulative: number;
    future: number;
    toDate: number;
}

export interface IPolicy {
    attachments?: IAttachment[];
    breakdown: Record<string, unknown>;
    cashMovements?: ICashMovements;
    partyCashMovements?: {
        [key: string]: ICashMovements;
    };
    currency?: string;
    grossPremium: IPayable;
    id: string;
    meta?: {
        language: string;
    };
    paymentSource?: string;
    policyholderId: string;
    productId: string;
    productVersion: number;
    reportingRef: string;
    effectiveFromDate: string;
    effectiveToDate: string;
    expiryDate: string;
    inceptionDate: string;
    issueDate?: string;
    payFrequency?: string; // "P1Y"
    policyType?: string; // "New"
    renewal: {
        previousPolicyId: string | null;
        version: number;
    },
    sections: { [key: string]: ISection };
    sequence?: number;
    sequenceId: string;
    status: EPolicyStatus;
    submission?: {
        aboutYou?: IAddress;
        yourDetails?: {
            homeAddress: IAddress;
        };
    };
    sumInsured: number;
    totalPayable: IPayable;
    totals: Partial<IBrokerage>;
    transactions: ITransaction[];
    ts?: string;
    version?: number;
    creation?: string;
    due?: string;
    paid?: string;
    totalAmount?: number;
    policyStatus?: string;
    attempts?: IAttempts[];
}

export enum EPolicyStatus {
    Bound = "Bound",
    Cancelled = "Cancelled",
    Dtq = "DTQ",
    New = "New",
    Ntu = "NTU",
    Quoted = "Quoted",
    Referral = "Referral",
}

export const PlaceHolder = "-";

interface IPayable {
    cumulative: number;
    delta: number;
}

export interface ISection {
    id: string;
    submission?: { sumInsured: number };
    breakdown?: IBrokerage;
    grossPremium?: IPayable;
    totalPayable?: IPayable;
    issues: IIssues;
    status: "OK";

    // totals: Partial<IBrokerage>;
    // cashMovements: {cumulative: 0, toDate: 0, future: 0, administrationToDate: 0, administrationCumulative: 0}

    // partyCashMovements: {}
    // quote: {currency: "GBP", premium: 107.91, quoteDate: "2020-12-10T14:14:58.627Z",…}
    // reportingRef: null
}
