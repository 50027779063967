import { IAuditEvent } from "../models";

export const auditEventsMock: IAuditEvent[] = [
    {
        detail: "Created a Blob with media type: application/json;charset=UTF-8",
        email: "ranvir.saggu@blocksure.com",
        eventType: "BLOB_CREATED",
        id: "aud_123",
        ip: "86.141.170.188",
        ts: "2021-10-08T10:42:34.134Z",
        userId: "usr_Bs2m38RaHhTCR1rf17D955",
    },
    {
        detail: "Policyholder has been updated with the id: 'pho_V2TQ4w82m8vnNJRmiKqg8D'",
        email: "antonio.navarro+10@blocksure.com",
        eventType: "POLICYHOLDER_UPDATED",
        id: "aud_345",
        ip: "92.58.68.67",
        ts: "2021-10-08T10:23:19.009Z",
        userId: "usr_WdK4dmf4mR9vd4FA3nEjyE",
    },
];
