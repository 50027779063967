import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    paperContent: {
        width: "100%",
        padding: theme.spacing(5, 5, 4),
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(5, 0, 0),
    },
    formGroup: {
        marginBottom: theme.spacing(2),
    },
    logo: {
        cursor: "pointer",
        height: "3rem",
    },
    centerWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
    iconWrapper: {
        marginRight: theme.spacing(1),
    },
    subtitle: {
        margin: theme.spacing(2, 0, 4),
    },
    successIcon: {
        color: theme.palette.success.main,
    },
    labelMarginLeft: {
        marginLeft: theme.spacing(1),
    },
}));
