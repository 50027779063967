import { ECurrencySymbol } from "../models/currency-symbol.model";
import { EStripeFee } from "../models/stripe-fee.model";

export class CurrencyUtils {
    // [SUR-132] retrieve this data from here: https://sysm.testbroker.co/payment/api/v1/currency
    static moneyPrecision: { [key: string]: number } = {};

    static _renderCurrency(val: number, locale: string, currency?: string, hideSymbol?: boolean) {
        if (val === undefined || val === null) {
            return "-";
        } else if (currency && !hideSymbol) {
            const options = {
                currency,
                minimumFractionDigits: CurrencyUtils.moneyPrecision[currency],
                maximumFractionDigits: CurrencyUtils.moneyPrecision[currency],
            };
            const displayAbsoluteValue = new Intl.NumberFormat(locale, options).format(Math.abs(val));
            const sign = val < 0 ? "-" : "";
            const symbol = ECurrencySymbol[currency] || currency;

            return `${sign}${symbol}${displayAbsoluteValue}`;
        } else {
            return new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(val); // render without currency symbol
        }
    }

    static getStripeFee(data: { currency: string }) {
        return EStripeFee[data.currency] || 0;
    }
}
