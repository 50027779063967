import { i18n, initI18, localeService } from "./services";

let inited = false;

localeService.locale$.subscribe(() => {
    const lang = localeService.getLanguage();
    if (!inited) {
        inited = true;
        initI18(lang)
            .then(() => localeService.onLoadI18(true))
            .catch((err) => console.error(err));
    } else {
        i18n.loadLanguages(lang).then((val) => {
            i18n.changeLanguage(lang);
            localeService.onLoadI18(true);
        });
    }
});
