import React from "react";
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from "@material-ui/core";
import { createGenerateClassName, StylesProvider, Theme, ThemeProvider } from "@material-ui/core/styles";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "inputcustom",
});

interface IProps {
    autoComplete?: string;
    autoFocus?: boolean;
    endAdornment?: any;
    error?: string;
    fullWidth?: boolean;
    id?: string;
    helperText?: string;
    label?: string;
    maxLength?: number;
    multiline?: boolean;
    name?: string;
    placeholder?: string;
    readOnly?: boolean;
    required?: boolean;
    minRows?: number;
    maxRows?: number;
    theme: Theme;
    touched?: boolean;
    type?: string;
    value?: string;
    disabled?: boolean;
    onBlur?: (e: React.ChangeEvent<any>) => void;
    onChange?: (e: React.ChangeEvent<any>) => void;
    onKeyPress?: (e: React.ChangeEvent<any>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const InputCustom: React.FC<IProps> = ({
    autoComplete,
    autoFocus,
    endAdornment,
    error,
    fullWidth,
    id,
    helperText,
    label,
    maxLength,
    multiline,
    name,
    placeholder,
    readOnly,
    required,
    minRows,
    maxRows,
    theme,
    touched,
    type = "text",
    value,
    disabled,
    onBlur,
    onChange,
    onKeyPress,
    onFocus,
    ...rest
}) => {
    const inputProps = { id, maxLength, "data-testid": "input-text" };

    // Prevent browser autofill password
    if (autoComplete === "new-password") {
        inputProps["data-lpignore"] = true;
    }

    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                {label && (
                    <InputLabel htmlFor={name} color="secondary" error={touched && Boolean(error)}>
                        {label}
                        {required ? "*" : ""}
                    </InputLabel>
                )}
                <FormControl error={touched && Boolean(error)} fullWidth variant="outlined">
                    <OutlinedInput
                        {...rest}
                        autoComplete={autoComplete}
                        autoFocus={autoFocus}
                        className={readOnly ? "read-only" : ""}
                        endAdornment={endAdornment}
                        fullWidth={fullWidth}
                        id={name}
                        inputProps={inputProps}
                        multiline={multiline}
                        name={name}
                        placeholder={placeholder}
                        minRows={minRows}
                        maxRows={maxRows}
                        readOnly={readOnly}
                        required={required}
                        type={type}
                        value={value}
                        disabled={disabled}
                        onBlur={onBlur}
                        onChange={onChange}
                        onKeyPress={onKeyPress}
                        onFocus={onFocus}
                    />
                    <FormHelperText>{touched ? error : helperText || ""}</FormHelperText>
                </FormControl>
            </ThemeProvider>
        </StylesProvider>
    );
};

export default React.memo(InputCustom);
