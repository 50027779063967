import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid/Grid";
import { Box, CircularProgress, createGenerateClassName, StylesProvider, Theme, ThemeProvider, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "preloader",
});

const useStyles = makeStyles((theme) => ({
    fullHeight: {
        height: "70vh",
    },
}));

interface IProps {
    fullHeight?: boolean;
    label?: boolean;
    theme?: Theme;
}

const Preloader: React.FC<IProps> = ({ fullHeight, label, theme }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <Grid container justifyContent="center" alignItems="center" classes={{ root: fullHeight ? classes.fullHeight : "" }} data-testid="preloader">
                    <Grid item>
                        <Box textAlign="center">
                            <CircularProgress color="primary" />
                        </Box>
                        {label && <Typography>{t("loading")}...</Typography>}
                    </Grid>
                </Grid>
            </ThemeProvider>
        </StylesProvider>
    );
};

export default Preloader;
