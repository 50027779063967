import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Grid, TablePagination, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Table from "./Table";
import Preloader from "../Preloader";
import { createGenerateClassName, StylesProvider, Theme, ThemeProvider } from "@material-ui/core/styles";
import { i18n } from "../../services";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "table-container",
});

interface IProps {
    blobLoading?: boolean;
    columns: any;
    data: any;
    displayedTableColumns?: any[];
    globalSearch?: string;
    loading?: boolean;
    needFooter?: boolean;
    refreshInterval?: number;
    rowsPerPageOptions?: number[];
    tabValue?: string;
    theme: Theme;
    title?: string;
    total?: number;
    view?: boolean;
    withBlob?: boolean;
    fetchData: (rowsPerPage: number, page: number, isRefresh?: boolean) => void;
}

const TableContainer: React.FC<IProps> = ({
    blobLoading,
    columns,
    data,
    displayedTableColumns,
    globalSearch,
    loading,
    needFooter,
    refreshInterval,
    rowsPerPageOptions = [10, 25, 50, 100, 200],
    tabValue,
    theme,
    title,
    total,
    view,
    withBlob,
    fetchData,
}) => {
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOptions ? rowsPerPageOptions[0] : 10);
    const parentContainerRef = useRef(null);

    useLayoutEffect(() => {
        let timerId;
        const getData = (isRefresh: boolean) => {
            fetchData(rowsPerPage, page, isRefresh);
            if (refreshInterval) {
                timerId = setTimeout(() => getData(true), refreshInterval);
            }
        };

        if (!withBlob) {
            getData(false);
        } else if (withBlob && displayedTableColumns && !blobLoading) {
            getData(false);
        }

        return () => {
            clearTimeout(timerId);
            timerId = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsPerPage, page, displayedTableColumns, blobLoading, tabValue]);

    useLayoutEffect(() => {
        if (tabValue) {
            setPage(0);
        }
    }, [tabValue]);

    useEffect(() => {
        setPage(0);
        setRowsPerPage(rowsPerPageOptions[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [total]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <Grid container spacing={1}>
                    {title && (
                        <Grid item xs={12}>
                            <Typography variant="h6">{title}</Typography>
                        </Grid>
                    )}

                    <Grid item xs={12} ref={parentContainerRef}>
                        {data && columns && columns.length > 0 && !loading && (
                            <>
                                <Table
                                    columns={columns}
                                    data={data}
                                    globalSearch={globalSearch}
                                    isRefreshInterval={!!refreshInterval}
                                    needFooter={needFooter}
                                    parentContainerRef={parentContainerRef}
                                    theme={theme}
                                    view={view}
                                />
                                {total > 0 && (
                                    <TablePagination
                                        component="div"
                                        count={total}
                                        labelRowsPerPage={i18n.t("rowsPerPage")}
                                        labelDisplayedRows={({ from, to, count }) =>
                                            `${from}-${to} ${i18n.t("of")} ${count !== -1 ? count : "more than " + to}`
                                        }
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        rowsPerPageOptions={rowsPerPageOptions}
                                    />
                                )}
                            </>
                        )}
                        {loading && (
                            <Box mt={20}>
                                <Preloader label={true} theme={theme} />
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </ThemeProvider>
        </StylesProvider>
    );
};

export default React.memo(TableContainer);
