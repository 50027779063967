import "regenerator-runtime/runtime";
import AuthApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/AuthApiClient";
import {namespacedLocalStorage as namespacedLS} from "./named-local-storage.service";
import {IAuthDetails} from "../models";

export default class AuthService {
    authDetailsKey = "authDetails";
    userDetailsKey = "userDetails";
    namespacedLocalStorage;
    authApiClient;
    redirect: Location;

    constructor(namespacedLocalStorage) {
        this.namespacedLocalStorage = namespacedLocalStorage;
        this.authApiClient = new AuthApiClient(namespacedLocalStorage);
    }

    hasPermission(permissions: string[], type = "any") {
        const authDetails: IAuthDetails = this._loadAuthDetails();

        if (!authDetails || !authDetails.permissions) {
            return false;
        }

        // not all menu items have permissions
        // so if it is empty let's show this menu item for all
        if (!permissions) {
            return true;
        }

        if (type === "all") {
            return permissions.every((p) => authDetails.permissions.includes(p));
        } else if (type === "any") {
            return authDetails.permissions.some((p) => permissions.includes(p));
        }
    }

    signIn(authDetails) {
        authDetails.signedIn = true;
        this._saveAuthDetails(authDetails);
    }

    setUser(data) {
        if (!data.firstName && !data.lastName) {
            const names = data.email.split("@")[0].split(".");
            data.firstName = names[0].charAt(0).toUpperCase() + names[0].slice(1);
            if (names.length === 2) {
                data.lastName = names[1].charAt(0).toUpperCase() + names[1].slice(1);
            } else {
                data.lastName = "";
            }
        }

        this.namespacedLocalStorage.setItem(this.userDetailsKey, JSON.stringify(data));
    }

    isSignedIn() {
        const authDetails = this._loadAuthDetails();
        if (!authDetails) return false;
        return !!(authDetails.signedIn && authDetails.username && authDetails.identityType);
    }

    signOut() {
        this.namespacedLocalStorage.removeItem(this.authDetailsKey);
        this.namespacedLocalStorage.removeItem(this.userDetailsKey);
        this.authApiClient.clearJwtToken();
    }

    loadAuthDetails() {
        return this._loadAuthDetails();
    }

    loadUserDetails() {
        const userDetails = this.namespacedLocalStorage.getItem(this.userDetailsKey);
        return userDetails && JSON.parse(userDetails);
    }

    _loadAuthDetails() {
        const authDetails = this.namespacedLocalStorage.getItem(this.authDetailsKey);
        const data = authDetails ? JSON.parse(authDetails) : {};

        // for testing different IdentityTypes
        // data.identityType = EIdentityType.Blocksure;
        // data.identityType = EIdentityType.Insurer;
        // data.party = 'C=GB,L=London,O=TestInsurer';

        // data.identityType ='Mga';
        // data.party = 'C=GB,L=London,O=TestMGA';
        return data;
    }

    _saveAuthDetails(authDetails) {
        authDetails = Object.assign({}, authDetails);
        this.namespacedLocalStorage.setItem(this.authDetailsKey, JSON.stringify(authDetails));
    }
}

export const signInService = new AuthService(namespacedLS);
