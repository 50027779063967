import { createTheme, lighten, responsiveFontSizes } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const COLORS = {
    black: "#2f2f2f",
    error: "#DE350A",
    info: "#1850BA",
    primary: "#2062c7",
    secondary: "#2f2f2f",
    success: "#00984C",
    white: "#f8f8f8",
    warning: "#ff9800",
};

const bgPercent = 0.9;
const bgPercentLabel = 0.8;
const bgHoveredPercent = 0.7;
const borderRadiusPx = 5;

const breakpoints = createBreakpoints({});
const fontFamily = ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(",");

// can not set directly in the overrides section
const MuiTabScrollButton = {
    MuiTabScrollButton: {
        root: {
            "&$disabled": {
                opacity: 0.3,
            },
        },
    },
};

const MuiAutocomplete = {
    MuiAutocomplete: {
        input: {
            paddingRight: "30px!important",
        },
    },
}

const theme = createTheme({
    overrides: {
        MuiAppBar: {
            root: {
                boxShadow: "none",
                fontFamily,
            },
        },
        ...MuiAutocomplete,
        MuiAvatar: {
            colorDefault: {
                backgroundColor: lighten(COLORS.primary, bgPercent),
            },
        },
        MuiButtonBase: {
            root: {
                backgroundColor: "none",
                color: "auto",
            },
        },
        MuiButton: {
            root: {
                padding: "6px 24px !important",
                borderRadius: `${borderRadiusPx}px !important`,
                boxShadow: "none!important",
                fontSize: "1rem",
                fontWeight: 500,
                lineHeight: "1.5rem!important",
                height: 40,
                textTransform: "none",
            },
            label: {
                whiteSpace: "nowrap",
            },
            textPrimary: {
                fontWeight: 500,
                backgroundColor: "transparent!important",
                "&:hover": {
                    backgroundColor: `${lighten(COLORS.primary, bgPercent)}!important`,
                },
            },
            textSecondary: {
                fontWeight: 500,
            },
            outlined: {
                backgroundColor: "transparent!important",
                "&:hover": {
                    backgroundColor: `${lighten(COLORS.primary, bgHoveredPercent)}!important`,
                },
            },
            outlinedSizeSmall: {
                padding: "3px 9px!important",
            },
        },
        MuiCardContent: {
            root: {
                "&:last-child": {
                    paddingBottom: "auto",
                }
            },
        },
        MuiCheckbox: {
            root: {
                backgroundColor: "transparent",
            },
        },
        MuiCssBaseline: {
            "@global": {
                html: {
                    WebkitFontSmoothing: "auto",
                    imageRendering: "-webkit-optimize-contrast",
                },
            },
        },
        MuiDialog: {
            paper: {
                border: "none!important",
            },
        },
        MuiFormHelperText: {
            root: {
                marginTop: 0,
            },
        },
        MuiIconButton: {
            root: {
                backgroundColor: "transparent",
            },
            colorPrimary: {
                padding: 6,
                backgroundColor: lighten(COLORS.primary, bgPercent),
                "&:hover": {
                    backgroundColor: lighten(COLORS.primary, bgHoveredPercent),
                },
            },
            colorSecondary: {
                padding: 6,
                backgroundColor: lighten(COLORS.secondary, bgPercent),
                "&:hover": {
                    backgroundColor: lighten(COLORS.secondary, bgHoveredPercent),
                },
            },
        },
        MuiInputBase: {
            root: {
                fontWeight: "300!important" as any,
                "&$disabled": {
                    opacity: 0.3,
                },
            },
        },
        MuiInputLabel: {
            root: {
                color: lighten(COLORS.black, 0.5),
                fontWeight: 300,
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: "transparent",
                border: `1px solid #ddd`,
                borderRadius: `${borderRadiusPx}px !important`,
                "&:hover, &$focused": {
                    backgroundColor: "white",
                },
            },
            underline: {
                borderBottom: COLORS.primary,
                "&:before": {
                    borderBottom: `1px solid #ddd`,
                },
                "&:hover:not($disabled):not($focused):not($error):before": {
                    borderBottom: `1px solid ${COLORS.primary} !important`,
                },
            },
        },
        MuiLink: {
            root: {
                fontWeight: "300!important" as any,
            },
        },
        MuiMenu: {
            paper: {
                border: "none",
                maxWidth: 500,
                maxHeight: 400,
            },
        },
        MuiMenuItem: {
            root: {
                overflow: "visible",
                whiteSpace: "normal",
                borderRadius: "0 !important",
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: `${borderRadiusPx}px !important`,
                // marginTop: 8,
                "&.input-secure": {
                    textSecurity: "disc",
                    webkitTextSecurity: "disc",
                },
                "&.read-only:hover input": {
                    cursor: "default",
                },
                "&.read-only .inputcustom-MuiOutlinedInput-input": {
                    cursor: "default",
                },
                "&.read-only .selectcustom-MuiSelect-iconOutlined": {
                    opacity: 0.2,
                },
                "&.read-only $notchedOutline, &.read-only:hover $notchedOutline": {
                    borderColor: lighten(COLORS.black, bgPercent),
                },
            },
            input: {
                padding: "10px",
                height: 40,
                boxSizing: "border-box",
            },
            multiline: {
                padding: 10,
                boxSizing: "border-box",
            },
        },
        MuiPaper: {
            root: {
                border: `1px solid ${lighten(COLORS.black, 0.8)}`,
            },
            rounded : {
                borderRadius: `${borderRadiusPx}px`,
            }
        },
        MuiRadio: {
            root: {
                backgroundColor: "transparent",
            },
        },
        MuiSelect: {
            root: {
                height: "40px !important",
            },
        },
        MuiTabs: {
            root: {
                minHeight: 40,
                "&.sub $indicator": {
                    height: 2,
                    backgroundColor: `${lighten(COLORS.primary, 0.7)}!important`,
                },
            },
            indicator: {
                height: 4,
                borderRadius: 2,
            },
        },
        MuiTab: {
            root: {
                paddingLeft: "8px!important",
                paddingRight: "8px!important",
                // lineHeight: '1.5rem!important',
                color: `${lighten(COLORS.black, 0.4)}!important`,
                backgroundColor: "transparent!important",
                fontSize: "1.05rem",
                fontWeight: 400,
                minWidth: "100px!important",
                minHeight: 40,
                borderBottom: `1px solid #ddd!important`,
                textTransform: "none",
                "&$selected, &:hover": {
                    color: `${COLORS.black}!important`,
                },
                "&.sub-tab": {
                    fontSize: 12,
                    maxWidth: "none",
                },
                "&$selected.sub-tab": {
                    backgroundColor: `${lighten(COLORS.primary, 0.85)}!important`,
                },
            },
        },
        ...MuiTabScrollButton,
        MuiTableCell: {
            root: {
                borderBottom: "none",
                fontWeight: 400,
                "&$sizeSmall": {
                    [breakpoints.down("xl")]: {
                        padding: 6,
                    },
                    [breakpoints.down("md")]: {
                        padding: 4,
                    },
                    [breakpoints.down("sm")]: {
                        padding: 2,
                    },
                },
                [breakpoints.down("md")]: {
                    fontSize: 12,
                },
            },
        },
        MuiTablePagination: {
            toolbar: {
                alignItems: "center !important",
            },
            caption: {
                [breakpoints.down("md")]: {
                    fontSize: 12,
                },
            },
            select: {
                height: "auto !important",
                [breakpoints.down("md")]: {
                    fontSize: 12,
                },
            },
            selectRoot: {
                [breakpoints.down("md")]: {
                    marginRight: 2,
                },
            },
        },
        MuiTypography: {
            body1: {
                fontSize: 14,
                fontWeight: 400,
                [breakpoints.down("md")]: {
                    fontSize: 12,
                },
            },
            body2: {
                fontSize: 14,
                fontWeight: 300,
                [breakpoints.down("md")]: {
                    fontSize: 12,
                },
            },
            caption: {
                color: lighten(COLORS.black, 0.5),
            },
            h6: {
                fontWeight: 400,
            },
            button: {
                padding: 3,
                backgroundColor: `${lighten(COLORS.primary, bgPercentLabel)}!important`,
                textTransform: "none",
                fontWeight: 300,
            },
        },
        MuiToolbar: {
            root: {
                alignItems: "stretch",
            },
        },
        MuiTableRow: {
            root: {
                borderBottom: `1px solid ${lighten(COLORS.black, bgPercent)}`,
                "&:hover": {
                    backgroundColor: `${lighten(COLORS.primary, bgPercent)}!important`,
                },
            },
        },
        MuiFormLabel: {
            root: {
                "&:not($error)": {
                    color: `${COLORS.black}!important`,
                },
                fontSize: 14,
            },
        },
    },
    palette: {
        action: {
            disabledBackground: "#dddddd",
            disabled: "#acacac",
        },
        background: {
            default: "#fff",
            paper: "#fff",
        },
        common: {
            black: COLORS.black,
            white: COLORS.white,
        },
        divider: lighten(COLORS.black, 0.9),
        error: {
            main: COLORS.error,
        },
        info: {
            main: COLORS.info,
        },
        primary: {
            main: COLORS.primary,
        },
        secondary: {
            main: lighten(COLORS.secondary, 0.4),
        },
        success: {
            main: COLORS.success,
        },
        text: {
            primary: COLORS.black,
            secondary: "",
        },
    },
    props: {
        MuiButton: {
            color: "primary",
            variant: "contained",
        },
        MuiPaper: {
            elevation: 0,
        },
        MuiTextField: {
            size: "small",
            variant: "filled",
        },
    },
    shape: {
        borderRadius: 0,
    },
    typography: {
        fontFamily,
    },
});

export const responsiveFontSizesTheme = responsiveFontSizes(theme);
