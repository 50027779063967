import React from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const View: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Button variant="outlined" color="primary" fullWidth size="small">
            {t("view")}
        </Button>
    );
};

export default View;
