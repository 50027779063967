import {i18n} from "../services";

export class TranslateUtils {
    static translateAltValue = (key: string, altValue: string) => {
        const result = i18n.t(key);

        return result === key ? altValue : result;
    };

    static translateRole = (name: string): { name: string; description: string } => {
        const key = name
            .toUpperCase()
            .replace(/ /g, "_")
            .replace(/ADMINISTRATOR/g, "ADMIN");

        const nameTranslated = i18n.t(key);
        let descriptionTranslated: string;

        // if found 'PRODUCT_ADMIN' try to find 'dPRODUCT_ADMIN'
        if (nameTranslated !== key) {
            const keyDescription = `d${key}`;
            const result = i18n.t(keyDescription);
            descriptionTranslated = result === keyDescription ? "" : result;
        }

        // return result === key ? name : result;

        return {
            name: nameTranslated === key ? name : nameTranslated,
            description: descriptionTranslated,
        };
    };
}
