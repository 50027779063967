import * as React from "react";
import { useEffect, useState } from "react";
import Link from "@material-ui/core/Link";
import { createGenerateClassName, StylesProvider, Theme, ThemeProvider } from "@material-ui/core/styles";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { history } from "../../helpers";
import { useTranslation } from "react-i18next";
import { AssetsService, i18n, signInService } from "../../services";
import { EIdentityType } from "../../models";
import { useStyles } from "./styles";
import { IDENTITY_TYPE_PLACEHOLDER, IMenuItem, ISubMenuItem, navProfileData, navsData, USER_NAME_PLACEHOLDER } from "./nav.model";
import Divider from "@material-ui/core/Divider";
import { Container, useMediaQuery } from "@material-ui/core";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "navbar",
});

interface IProps {
    theme: Theme;
}

export const NavBar: React.FC<IProps> = ({ theme }) => {
    const classes = useStyles(theme)();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
    const [navProfile, setNavProfile] = useState<IMenuItem>({} as IMenuItem);
    const [authDetails] = useState(signInService.loadAuthDetails());
    const [userDetails] = useState(signInService.loadUserDetails());
    const [navs, setNavs] = useState<IMenuItem[]>([]);
    const [subMenu, setSubMenu] = useState(null);
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

    const { t } = useTranslation();

    useEffect(() => {

        const navs = navsData()
            .map((menu) => {
                if (menu.hasOwnProperty("experimentalFeature") && menu.experimentalFeature) return null;
                if (!signInService.hasPermission(menu.permissions, menu.permissionType)) return null;

                const subMenuWithPermissions = menu.subMenu
                    ? menu.subMenu
                        .filter((m) => signInService.hasPermission(m.permissions, m.permissionType))
                        .filter((m) => !(m.hasOwnProperty("experimentalFeature") && m.experimentalFeature))
                    : null;

                // if nothing to show in SubMenu then hide Menu too
                if (Array.isArray(subMenuWithPermissions) && !subMenuWithPermissions.length) return null;

                const subMenuData =
                    menu.text === IDENTITY_TYPE_PLACEHOLDER && authDetails.identityType === EIdentityType.Blocksure ? null : subMenuWithPermissions;
                const subMenu = subMenuData ? subMenuData.map((item) => translateMenuItem(item)) : null;
                return {
                    ...translateMenuItem(menu),
                    subMenu,
                };
            })
            .filter((nav) => nav); // if no permissons skip menu items with null

        setNavs(navs);
        const name = userDetails ? `${userDetails.firstName} ${userDetails.lastName}` : authDetails.username;
        setNavProfile({
            text: name || t(navProfileData.text),
            subMenu: navProfileData.subMenu.map((item) => ({ ...item, text: t(item.text) })),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language, i18n.languages, authDetails]);

    // for mobile layout create flat array with all nested submenus item
    const mergeNavItems = (items: IMenuItem[]) => {
        return items.reduce((result, nav) => {
            const subMenu = nav.subMenu ? nav.subMenu.map((s) => ({ ...s, isSubmenu: true })) : [];
            return [...result, nav, ...subMenu];
        }, []);
    };

    // Insurer/Broking/MGA (depending on the user’s identityType. blocksure must be treated as broker)
    const getNameByIdentityType = () => {
        switch (authDetails.identityType) {
            case EIdentityType.Blocksure:
                return "Blocksure";
            case EIdentityType.Insurer:
                return t("insurerMenu");
            case EIdentityType.MGA:
                return t("mga");
            default:
                return t("agencyMenu");
        }
    };

    const translateMenuItem = (item: IMenuItem) => ({
        ...item,
        dataTestId: item.text,
        text: item.text === IDENTITY_TYPE_PLACEHOLDER ? getNameByIdentityType() : t(item.text),
    });

    const handleMenuClick = (nav: IMenuItem) => (event: React.MouseEvent<HTMLElement>) => {
        if (nav.subMenu) {
            handleMenuOpen(nav.subMenu)(event);
        }
        if (nav.path) {
            redirect(nav.path, true)(event);
        }
    }

    const handleMenuClose = () => setAnchorEl(null);

    const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

    const handleMenuOpen = (values: ISubMenuItem[]) => (event: React.MouseEvent<HTMLElement>) => {
        setSubMenu(values);
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const redirect = (path: string, silent = false) => (event: React.MouseEvent<HTMLElement>) => {
        if (!silent) {
            handleMenuClose();
            handleMobileMenuClose();

        // Reports (on its own): nothing happens.
        } else if (location.pathname.startsWith(`/surelync${path}`)) {
            return;
        }

        // Sign Out
        if (path === "/login") {
            signInService.signOut();
        }

        history.push(path);
    };

    const redirectHome = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        handleMenuClose();
        history.push({
            pathname: "/",
            state: { resetSearch: true },
        });
    };

    const mobileMenuId = "navbar-menu-mobile";
    const renderMobileMenu = (
        <Menu anchorEl={mobileMoreAnchorEl} id={mobileMenuId} open={Boolean(mobileMoreAnchorEl)} onClose={handleMobileMenuClose}>
            {mergeNavItems(navs).map((nav, index) => (
                <MenuItem key={index} disabled={!nav.path} onClick={nav.path ? redirect(nav.path) : null}>
                    <Box ml={nav.isSubmenu ? 2 : 0}>
                        <Typography variant={nav.isSubmenu ? "body2" : "body1"}>{nav.text}</Typography>
                    </Box>
                    {nav.subMenu && nav.subMenu.length ? <ExpandMoreIcon fontSize="small" /> : null}
                </MenuItem>
            ))}
            <Divider />
            {mergeNavItems([navProfile]).map((nav, index) => (
                <MenuItem key={index} disabled={!nav.path} onClick={nav.path ? redirect(nav.path) : null}>
                    <Box ml={nav.isSubmenu ? 2 : 0}>
                        <Typography variant={nav.isSubmenu ? "body2" : "body1"}>{nav.text}</Typography>
                    </Box>
                    {nav.subMenu && nav.subMenu.length ? <ExpandMoreIcon fontSize="small" /> : null}
                </MenuItem>
            ))}
        </Menu>
    );

    const menuId = "navbar-menu-item";
    const renderSubMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            classes={{ paper: classes.menuPaper, list: classes.menuList }}
            id={menuId}
            getContentAnchorEl={null}
            open={Boolean(anchorEl)}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            onClose={handleMenuClose}
        >
            {subMenu
                ? subMenu.map((item: ISubMenuItem, index: number) => (
                    <MenuItem key={index} classes={{ root: classes.menuItem }} onClick={redirect(item.path)}>
                        {item.divider && (<div className={clsx([classes.menuDivider, classes[item.divider]])}>
                            <div></div>
                        </div>)}
                        {item.text}
                    </MenuItem>
                ))
                : null}
        </Menu>
    );

    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <AppBar color="primary" position="static" classes={{ root: classes.paper }}>
                    <Toolbar classes={{ root: isSmall ? classes.noPadding : "" }}>
                        <Container maxWidth="xl">
                            <Grid container direction="row" alignItems="stretch">
                                <Grid item className={clsx([classes.item, classes.clickable, classes.noPadding])}>
                                    <Link href="/surelync" onClick={redirectHome}>
                                        <img
                                            className={classes.logo}
                                            src={`${AssetsService.basePath()}images/home-logo.png`}
                                            alt="surelync logo"
                                            data-testid="surelync-logo"
                                        />
                                    </Link>
                                </Grid>
                                {/* Menu for the Desktop Layout */}
                                <Hidden smDown>
                                    {navs.map((nav, index) => (
                                        <Grid
                                            key={index}
                                            item
                                            className={clsx([classes.item, classes.clickable])}
                                            data-testid={`nav-${nav.dataTestId}-id`}
                                            onClick={handleMenuClick(nav)}
                                        >
                                            {nav.text}
                                            {nav.subMenu && nav.subMenu.length ? <ExpandMoreIcon fontSize="small" /> : null}
                                        </Grid>
                                    ))}
                                    <Grid item xs />
                                    <Grid item className={classes.item}>
                                        <IconButton classes={{ root: classes.iconBtn }} data-testid="nav-search-icon-id" onClick={redirectHome}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid
                                        item
                                        className={classes.item}
                                        data-testid={`nav-${USER_NAME_PLACEHOLDER}-id`}
                                        // style={{paddingRight: 0}}
                                        onClick={handleMenuOpen(navProfile.subMenu)}
                                    >
                                        <Typography
                                            classes={{
                                                root: clsx([classes.roundedBtn, classes.clickable]),
                                            }}
                                            noWrap
                                        >
                                            {navProfile.text} <ExpandMoreIcon fontSize="small" style={{ verticalAlign: "middle" }} />
                                        </Typography>
                                    </Grid>
                                </Hidden>
                                {/* Menu for the Mobile Layout */}
                                <Hidden mdUp>
                                    <Grid item xs />
                                    <Grid item className={classes.item}>
                                        <IconButton classes={{ root: classes.iconBtn }} onClick={handleMobileMenuOpen}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Grid>
                                </Hidden>
                            </Grid>
                        </Container>
                    </Toolbar>
                </AppBar>
                {renderSubMenu}
                {renderMobileMenu}
            </ThemeProvider>
        </StylesProvider>
    );
};
