import { IAttachment } from "./attachment.model";

export interface IClaim {
    attachments?: IAttachment[];
    category: string;
    claimant: string;
    claimAmount: number;
    claimFee: number;
    creationDate: string;
    currency: string;
    dateOfIncident: string;
    id: string;
    lossDescription: string;
    policyId: string;
    productId: string;
    reportingRef: string;
    sequenceId: string;
    status: EClaimStatus;
    subCategory: string;
    ts: string;
}

export enum EClaimStatus {
    New = "New",
}
