import { INote } from "../models";

export const noteMock: INote = {
    authorId: "usr_123",
    id: "not_123",
    email: "test@email.com",
    description: "Note message 1",
    ownerId: "pho_123-notes",
    ts: "2020-07-01",
};

export const enquiryMock: INote = {
    authorId: "usr_123",
    id: "not_123",
    email: "test@email.com",
    description: "Enquiry message 1",
    ownerId: "pho_123-enquires",
    ts: "2020-07-01",
};
