import { EClaimStatus, IClaim } from "../models";

export const claimMock: IClaim = {
    attachments: [],
    category: "Lost",
    claimAmount: 0,
    claimFee: 0,
    claimant: "pho_123",
    creationDate: "2020-09-09T09:42:29.891Z",
    currency: "GBP",
    dateOfIncident: "2020-09-10T09:41:44.691Z",
    id: "clm_123",
    lossDescription: "Loss Description",
    policyId: "pol_123",
    productId: "prd_123",
    reportingRef: "CLA123",
    sequenceId: "seq_123",
    status: EClaimStatus.New,
    subCategory: "",
    ts: "2020-09-09T09:42:29.891Z",
};
