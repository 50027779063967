import { makeStyles, Theme } from "@material-ui/core/styles";
import { ETableSize } from "../../../models";

export const useStyles = (theme: Theme) =>
    makeStyles(() => ({
        cellWrap: {
            whiteSpace: "normal",
            wordWrap: "break-word",
            width: 150,
            position: "relative",
        },
        cellWrapSmall: {
            padding: "6px",
            whiteSpace: "normal",
            wordWrap: "break-word",
            width: 20,
            position: "relative",
        },
        extrsSmall: {
            whiteSpace: "normal",
            wordWrap: "break-word",
            minWidth: ETableSize.extrsSmall,
            maxWidth: ETableSize.extrsSmall,
            width: ETableSize.extrsSmall,
            position: "relative",
        },
        small: {
            whiteSpace: "normal",
            wordWrap: "break-word",
            minWidth: ETableSize.small,
            width: ETableSize.small,
            position: "relative",
        },
        medium: {
            whiteSpace: "normal",
            wordWrap: "break-word",
            minWidth: ETableSize.medium,
            width: ETableSize.medium,
            position: "relative",
        },
        extraMedium: {
            whiteSpace: "normal",
            wordWrap: "break-word",
            minWidth: ETableSize.extraMedium,
            maxWidth: ETableSize.extraMedium,
            width: ETableSize.extraMedium,
            position: "relative",
        },
        large: {
            whiteSpace: "normal",
            wordWrap: "break-word",
            minWidth: ETableSize.large,
            width: ETableSize.large,
            position: "relative",
        },
    }));
