import { EPolicyStatus, IPolicy } from "../models";

export const policyMock: IPolicy = {
    attachments: [],
    breakdown: {},
    cashMovements: {
        administrationCumulative: 1,
        administrationToDate: 1,
        cumulative: 2,
        future: 2,
        toDate: 2,
    },
    totals: {
        Brokerage: {
            cumulative: 3,
            delta: 3,
        },
        IPT: {
            cumulative: 11,
            delta: 11,
        },
        "MGA Commission": {
            cumulative: 4,
            delta: 4,
        },
        "Net Brokerage": {
            cumulative: 11,
            delta: 11,
        },
        "Net Net Premium": {
            cumulative: 11,
            delta: 11,
        },
        "Net MGA Commission": {
            cumulative: 11,
            delta: 11,
        },
    },
    grossPremium: {
        cumulative: 7,
        delta: 2,
    },
    id: "pol_123",
    partyCashMovements: {
        "C=GB,L=London,O=TestBroker": {
            administrationCumulative: 1,
            administrationToDate: 1,
            cumulative: 3,
            future: 1,
            toDate: 2,
        },
    },
    policyholderId: "pho_123",
    productId: "prd_123",
    productVersion: 0,
    reportingRef: "pol_123",
    effectiveFromDate: "2020-07-01",
    effectiveToDate: "2020-09-01",
    expiryDate: "2020-09-01",
    inceptionDate: "2020-08-01",
    renewal: {
        previousPolicyId: null,
        version: 0
    },
    sections: {
        pedalCycles: {
            id: "pos_123",
            issues: {},
            breakdown: {
                Brokerage: {
                    cumulative: 3,
                    delta: 3,
                },
                IPT: {
                    cumulative: 11,
                    delta: 11,
                },
                "MGA Commission": {
                    cumulative: 4,
                    delta: 4,
                },
                "Net Brokerage": {
                    cumulative: 11,
                    delta: 11,
                },
                "Net Net Premium": {
                    cumulative: 11,
                    delta: 11,
                },
                "Net MGA Commission": {
                    cumulative: 11,
                    delta: 11,
                },
            },
            status: "OK",
            submission: {
                sumInsured: 100,
            },
            totalPayable: {
                cumulative: 0,
                delta: 20,
            },
        },
        possessions: {
            id: "pos_123",
            issues: {},
            status: "OK",
        },
    },
    sequenceId: "seq_123",
    status: EPolicyStatus.Bound,
    submission: {
        aboutYou: {
            country: "UK",
            county: "North Yorkshire",
            line1: "11 Ripley Drive",
            line2: "",
            postcode: "HG13JD",
            town: "Harrogate",
        },
    },
    sumInsured: 100,
    totalPayable: {
        cumulative: 0,
        delta: 20,
    },
    transactions: [],
};

export const policyWithCancelledStatus: IPolicy = {
    ...policyMock,
    status: EPolicyStatus.Cancelled,
}

export const policyWithInvalidInceptionDate: IPolicy = {
    ...policyMock,
    inceptionDate: "2020-09-01",
}

export const policyWithInvalidExpireDate: IPolicy = {
    ...policyMock,
    expiryDate: new Date().toDateString(),
}

export const sequencesMock: IPolicy[] = [
    {
        ...policyMock,
        sequenceId: "seq_123",
        sequence: 0,
        version: 1,
    },
    {
        ...policyMock,
        sequenceId: "seq_124",
        sequence: 1,
        version: 1,
    },
];

export const settlementsMock = [
    {
        available: "2020-09-17T19:40:45.986Z",
        batchId: null,
        currency: "GBP",
        grossAmount: 0.449999988,
        id: "set_123",
        netAmount: 0.189999998,
        paid: "2020-09-17T19:26:45.987Z",
        party: "C=GB,L=London,O=TestBroker",
        paymentFee: 0.25999999,
        paymentGateway: "C=GB,L=London,O=Blocksure",
        reportingRef: "BS000223",
        sequenceId: "seq_123",
        settled: null,
        status: "Paid",
        transactionId: "tra_123",
    },
];
