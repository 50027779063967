import React, { useEffect, useRef, useState } from "react";
import { Theme } from "@material-ui/core/styles";
import { getCountries } from "../../utils";
import AutocompleteCustom from "../AutocompleteCustom";

interface ISelectOption {
    label: string;
    value: string;
}

interface IProps {
    disabled?: boolean;
    error: string;
    fullWidth?: boolean;
    getLabel?: boolean;
    label: string;
    name: string;
    readOnly?: boolean;
    theme: Theme;
    touched: boolean;
    value: string;
    onChange: (event: React.ChangeEvent, option: ISelectOption | string) => void;
}

export const CountriesSelect: React.FC<IProps> = ({ disabled, error, label, readOnly, touched, theme, value, onChange, ...props }) => {
    const options = useRef(null);
    const [selected, setSelected] = useState(null);

    const select = () => {
        if (!options.current) {
            return;
        }

        const selected = options.current.find((it) => it.value === value) || "";
        setSelected(selected);
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getCountries();
                options.current = data;
                select();
            } catch (error) {
                console.error(error);
            }
        };
        if (!readOnly) {
            fetchData();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        select();
        // eslint-disable-next-line
    }, [value]);
    
    return (
        <AutocompleteCustom
            disabled={disabled}
            error={error}
            label={label}
            options={options.current || []}
            readOnly={readOnly}
            theme={theme}
            touched={touched}
            value={selected}
            onChange={onChange}
            {...props}
        />
    );
};