import React, { useState } from "react";
import {
    Box,
    Button,
    createGenerateClassName,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    StylesProvider,
    Theme,
    ThemeProvider,
    Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import InputCustom from "../InputCustom";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "promt-dialog",
});

interface PromptDialogProps {
    message?: string;
    open: boolean;
    placeholder?: string;
    theme: Theme;
    title?: string;
    onClose: (value: string) => void;
}

export const PromptDialog: React.FC<PromptDialogProps> = ({ message, open, placeholder, theme, title, onClose }) => {
    const [value, setValue] = useState("");
    const { t } = useTranslation();

    const handleCancel = () => {
        onClose("");
        setValue("");
    };
    const handleOk = () => {
        onClose(value.trim());
        setValue("");
    };

    const handleClose = (event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return false;
        }
        handleCancel();
    };

    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <Dialog maxWidth="sm" aria-labelledby="confirmation-dialog-title" open={open} onClose={handleClose}>
                    {title ? <DialogTitle id="confirm-dialog-title">{title}</DialogTitle> : null}
                    <DialogContent dividers>
                        {message ? (
                            <Box m={3}>
                                <Typography variant="body1">{message}</Typography>
                            </Box>
                        ) : null}
                        <Box>
                            <InputCustom
                                autoComplete="off"
                                name="description"
                                maxLength={50}
                                placeholder={placeholder}
                                theme={theme}
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel} color="secondary">
                            {t("cancel")}
                        </Button>
                        <Button autoFocus onClick={handleOk} color="primary">
                            {t("ok")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </StylesProvider>
    );
};
