export enum ETransactionStatus {
    Cancelled = "Cancelled",
    Failed = "Failed",
    Paid = "Paid",
    Pending = "Pending",
}

export enum ETransactionType {
    Collection = "Collection",
    Correction = "Correction",
    Refund = "Refund",
}

export interface ITransaction {
    componentAmounts?: {
        administration: number;
    };
    creation: string; // Transaction creation date
    currency: string;
    due: string;
    id: string;
    partyComponentAmounts: {
        [key: string]: {
            // 'C=GB,L=London,O=Blocksure': {additional: 0, administration:0, ...}
            additional: number;
            administration: number;
            correction: number;
            original: number;
            refund: number;
        };
    };
    paid: string;
    status: ETransactionStatus;
    totalAmount: number;
    transactionType?: ETransactionType;
}
