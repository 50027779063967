import axios from "axios";
import { AssetsService } from "../services";

interface ISelectOption {
    currency: string;
    label: string;
    value: string;
}

let options: ISelectOption[];

export const getCountries = async () => {
    if (options) {
        return options;
    }
    const { data } = await axios.get(`${AssetsService.basePath()}countries.json`);
    options = data.map((it) => ({ currency: it.currency, label: `${it.nativeName} (${it.alpha2Code})`, value: it.alpha2Code }));
    return options;
};
