export const generateCsvData = (headerColumns, data) => {
    const columns = headerColumns.filter((column) => column.toCSV).map((column) => column.accessor);

    const printableColumns: string[] = [];
    columns.forEach((name) => {
        const column = headerColumns.find((c) => c.accessor === name);
        printableColumns.push(...column.toCSV.headers);
    });

    const printableData = data.map((row) => {
        const result = [];
        columns.forEach((name) => {
            const column = headerColumns.find((c) => c.accessor === name);
            if (column.toCSV.format) {
                result.push(column.toCSV.format(row));
            } else if (column.toCSV.values) {
                column.toCSV.values.forEach((fieldName) => result.push(row[fieldName]));
            } else {
                result.push(row[name]);
            }
        });
        return result;
    });

    return [printableColumns, ...printableData];
};
