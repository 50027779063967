import { IPolicyholder, PlaceHolder } from "../models";
import {StartingPrefix} from "@blocksure/blocksure-core/src/components/PhoneNumberUtils";

export class PolicyholderUtils {
    static renderAddress(policyholder: IPolicyholder, shared, fieldOrder = defaultFieldOrder) {
        const { schemaCountry } = policyholder.shared;
        const addressFields = [];
        const address = shared.address || shared.homeAddress || shared;
        const prefixes = ["", "address.", "homeAddress."];
        if (schemaCountry === "JP") {
            fieldOrder = fieldOrder.filter((it: string) => it !== "country");
        }
        fieldOrder.forEach((key) => {
            prefixes.forEach((prefix) => {
                const withPrefix = `${prefix}${key}`;
                // if (withPrefix in address) addressFields.push(this.lineOrEmpty(key, address[withPrefix]));
                if (address[withPrefix]) addressFields.push(address[withPrefix]);
            });
        });
        return addressFields.join(", ");
    }

    static _getAddress(shared, fieldOrder = defaultFieldOrder): { [key: string]: string } {
        const addressFields = {};
        const address = shared.address || shared.homeAddress || shared;
        const prefixes = ["", "address.", "homeAddress."];
        fieldOrder.forEach((key) => {
            prefixes.forEach((prefix) => {
                const withPrefix = `${prefix}${key}`;
                // if (withPrefix in address) addressFields.push(this.lineOrEmpty(key, address[withPrefix]));
                if (address[withPrefix]) addressFields[key] = address[withPrefix];
            });
        });
        return addressFields;
    }

    static _renderDateOfBirth(policyholder: IPolicyholder) {
        return policyholder.shared?.personal.dateOfBirth || PlaceHolder;
    }

    static _renderFullName(policyholder: IPolicyholder) {
        if (!policyholder || !policyholder.shared || !policyholder.shared.personal) {
            return "";
        }

        const { personal, schemaCountry } = policyholder.shared;

        if (schemaCountry === "JP") {
            // The bracket used here is a spacial japanese character which has a half space before it. '（'
            return personal.lastNameFurigana || personal.firstNameFurigana
                ? `${personal.lastName} ${personal.firstName}（${personal.lastNameFurigana} ${personal.firstNameFurigana}）`
                : `${personal.lastName} ${personal.firstName}`;
        } else {
            return PolicyholderUtils._renderSearchFullName(personal);
        }
    }

    static _renderSearchFullName({ title, firstName, lastName }) {
        const result = [];

        const addPart = (part: string) => part && result.push(part);

        addPart(title);
        addPart(firstName);

        if (lastName) {
            addPart(lastName);
        }

        return result.join(" ");
    }

    // Fix formatting when we get 447399903999 we return 07399903999
    static _renderPhoneNumber(phoneNumberString: string) {
        const formattedNumber = phoneNumberString.replace("+", "");
        const prefix = StartingPrefix(formattedNumber, "");
        if (prefix === undefined || prefix == null) {
            return formattedNumber;
        }
        const phoneNumber = formattedNumber.replace(prefix.replace("+", ""), "");
        if (phoneNumber.startsWith("0")) {
            return phoneNumber;
        } else {
            return "0" + phoneNumber;
        }
    }
}

const defaultFieldOrder = [
    // Building fields
    "buildingName",
    "buildingAndFloor",
    // Street fields.
    "streetNameAndNumber",
    "line1",
    // Locality fields.
    "line2",
    "line3",
    "line4",
    "area",
    "areaNumber",
    // Minor settlement.
    "village",
    "town",
    // Division.
    "ward",
    "subdistrict",
    "prefecture",
    // Major Settlement.
    "city",
    // Principal administrative division of country.
    "province",
    "subzone",
    "zone",
    "state",
    "region",
    "county",
    // Country
    "country",
    // Postcode.
    "regencyPostalCode",
    "postcode",
];
