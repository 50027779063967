import React, { useLayoutEffect, useRef, useState } from "react";
import { useGlobalFilter, useRowSelect, useSortBy, useTable } from "react-table";

import { Table as MaUTable, TableBody, TableHead, TableFooter } from "@material-ui/core";
import { createGenerateClassName, StylesProvider, Theme, ThemeProvider } from "@material-ui/core/styles";

import HeaderRow from "./HeaderRow";
import FooterRow from "./FooterRow";
import BodyRow from "./BodyRow";
import { ETableSize, ITableColumn, ITableData } from "../../models";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "table",
});

interface ITableProps {
    columns: ITableColumn[];
    data: ITableData[];
    globalSearch?: string;
    isRefreshInterval?: boolean;
    needFooter?: boolean;
    parentContainerRef?: any;
    theme: Theme;
    view: boolean;
}

const Table: React.FC<ITableProps> = ({ theme, columns, isRefreshInterval, data, globalSearch, needFooter, view, parentContainerRef,  }) => {
    const [cell, setCell] = useState<any>([]);
    const [isCollapseTable, setIsCollapseTable] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(null);
    const [sort, setSort] = useState<{ id: string; isSorted: boolean; isSortedDesc: boolean }>(null);
    const tableRef = useRef(null);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter, footerGroups } = useTable(
        {
            data,
            columns,
            initialState: {
                sortBy: sort?.isSorted ? [{ id: sort.id, desc: sort.isSortedDesc }] : [],
            },
        },
        useGlobalFilter,
        useSortBy,
        useRowSelect
    );

    (data as any).sortIndexes = rows.map((r) => r.index);

    useLayoutEffect(() => {
        setGlobalFilter(globalSearch || undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, globalSearch]);

    useLayoutEffect(() => {
        function updateSize() {
            if (parentContainerRef.current) {
                setWidth(parentContainerRef.current.offsetWidth);

                // fix for mobile
            } else {
                setWidth(tableRef.current.parentElement.offsetWidth);
            }
        }

        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        const allNeededSize = headerGroups[0].headers.reduce((accum, current) => {
            return accum + (Number(ETableSize[current.size]) || 150);
        }, 50);

        setIsCollapseTable(allNeededSize > width);

        if (width) {
            const newCell = [{ count: 0, size: isCollapseTable ? 50 : 0 }];

            for (let i = 0; i < headerGroups[0].headers.length; i++) {
                const rowSize = Number(ETableSize[headerGroups[0].headers[i].size]) || 150;

                const item = newCell[newCell.length - 1];
                if (item.size + rowSize <= width) {
                    newCell[newCell.length - 1] = {
                        count: item.count + 1,
                        size: item.size + rowSize,
                    };
                } else {
                    newCell.push({ count: 1, size: rowSize });
                }
            }

            setCell(newCell);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);
    
    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <MaUTable {...getTableProps()} size="small" style={{ tableLayout: "fixed" }} ref={tableRef}>
                    <TableHead>
                        {cell[0] &&
                            headerGroups.map((headerGroup) => {
                                const firstHeaderRows = isCollapseTable ? headerGroup.headers.slice(0, cell[0].count) : headerGroup.headers;
                                return (
                                    <HeaderRow
                                        headerGroup={headerGroup}
                                        headers={firstHeaderRows}
                                        isCollapseTable={isCollapseTable}
                                        isRefreshInterval={isRefreshInterval}
                                        theme={theme}
                                        onSort={(id: string, isSorted: boolean, isSortedDesc: boolean) =>
                                            setSort({
                                                id,
                                                isSorted,
                                                isSortedDesc,
                                            })
                                        }
                                    />
                                );
                            })}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <BodyRow
                                    cell={cell}
                                    headerGroups={headerGroups}
                                    isCollapseTable={isCollapseTable}
                                    row={row}
                                    theme={theme}
                                    view={view}
                                />
                            );
                        })}
                    </TableBody>
                    {needFooter && cell[0] && footerGroups ? (
                        <TableFooter>
                            {footerGroups.map((footerGroup) => {
                                const firstHeaderRows = isCollapseTable ? footerGroup.headers.slice(0, cell[0].count) : footerGroup.headers;
                                return <FooterRow footerGroup={footerGroup} footers={firstHeaderRows} theme={theme} />;
                            })}
                        </TableFooter>
                    ) : null}
                </MaUTable>
            </ThemeProvider>
        </StylesProvider>
    );
};

export default React.memo(Table);
