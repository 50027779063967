import { EIdentityType, EPolicyStatus, ETransactionStatus, IPolicy, PlaceHolder, IPolicyholder, IProduct } from "../models";
import { i18n } from "../services";
import { PolicyUtils, PolicyholderUtils, ProductUtils } from ".";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

export const checkUpcomingStatus = (item: IPolicy): boolean => item.status === EPolicyStatus.Bound;
export const checkUpcomingExpireToToday = (item: IPolicy): boolean => FormatUtils.compareDates(FormatUtils.parseDate(item.expiryDate), FormatUtils.today(), "weeks") <= 3;
export const checkUpcomingExpireToInception = (item: IPolicy): boolean => FormatUtils.compareISODates(item.expiryDate, item.inceptionDate, "weeks") > 4;

export const getUpcomingStatus = (item: IPolicy): boolean => (
    checkUpcomingStatus(item) &&
    checkUpcomingExpireToToday(item) &&
    checkUpcomingExpireToInception(item)
);

export const setDataPolicies = (
    identityType: EIdentityType,
    policies: { items: IPolicy[] },
    products: { [key: string]: IProduct },
    policyholders: { [key: string]: IPolicyholder }
) =>
    policies.items.map((item) => {
        const policyholder: IPolicyholder = policyholders[item.policyholderId];
        const product: IProduct = products[item.productId];

        const policyNo = item.reportingRef;
        const quoteNo = item.reportingRef;
        const accountBalance = item.transactions.reduce(
            (total, transaction) => (transaction.status === ETransactionStatus.Pending ? total + transaction.totalAmount : total),
            0
        );
        const brokerage = PolicyUtils.getValueByKey(item.totals, "Brokerage", "delta");
        const expiry = PolicyUtils.getExpiryDateTime(item);
        const ipt = PolicyUtils.getValueByKey(item.totals, "IPT", "delta");
        const taxIpt = PolicyUtils.getValueByKey(item.totals, "Tax", "delta");
        const premium = item.grossPremium ? item.grossPremium.delta : null;
        const totalDeductions = PolicyUtils._getTotalDeduction(item);
        const totalDeductionsPercent = PolicyUtils._getTotalDeductionsPercent(item);
        const partyBroker = ProductUtils._formatParty(product.partiesAndRoles.Broker);
        const partyInsurer = ProductUtils._formatParty(product.partiesAndRoles.Insurer);
        const partyMga = ProductUtils._formatParty(product.partiesAndRoles.MGA);
        const upcoming = getUpcomingStatus(item);

        let percentAmount = PolicyUtils.getSplitPercentage(product, "Brokerage");

        if (!percentAmount) {
            percentAmount = Math.round((brokerage / premium) * 100);
        } else {
            percentAmount = parseInt(percentAmount, 10); // remove %
        }

        let agent: string;
        let insurer: string;
        let mga: string;

        // MGAs do not want to share insurer details with brokers and broker details with insurers.
        if (identityType === EIdentityType.Blocksure || identityType === EIdentityType.Broker) {
            agent = PlaceHolder;
            insurer = partyMga ? PlaceHolder : partyInsurer;
            mga = partyMga || PlaceHolder;
        } else if (identityType === EIdentityType.Insurer) {
            agent = partyMga ? PlaceHolder : partyBroker;
            insurer = PlaceHolder;
            mga = partyMga || PlaceHolder;
        } else if (identityType === EIdentityType.MGA) {
            agent = partyBroker || PlaceHolder;
            insurer = partyInsurer || PlaceHolder;
            mga = partyMga || PlaceHolder;
        }

        let totalPayableByPolicyholder = item?.totalPayable?.delta;

        // (A) Add the Admin fee payable to an intermediary to the total payable column in the broker view and MGA view of SureLync.
        if (identityType === EIdentityType.Broker || identityType === EIdentityType.MGA) {
            totalPayableByPolicyholder += PolicyUtils.getValueByKey(item.totals, "MTA Admin Fee", "delta");
        }

        // (B) Ensure that the “Cumulative” value for breakdown/”MTA Admin fee” is the cumulative value of MTA Admin fees for the policy to date.
        // FIXME backend not ready.

        return {
            policyNo,
            quoteNo,
            policyNoAndQuoteNo: `${policyNo}${quoteNo}`, // it use 'Filter this page' component
            email: policyholder?.primaryEmailAddress || "",
            policyholder: PolicyholderUtils._renderFullName(policyholder),
            status: PolicyUtils.getStatus(item.status),
            effective: item.effectiveFromDate,
            renew: item.effectiveToDate,
            product: product.name,
            mga,
            isMultiSections: Object.keys(item.sections).length > 1,
            agent,
            grossBrokerage: PolicyUtils.getValueByKey(item.totals, "Brokerage", "cumulative"),
            premium,
            accountBalance,
            lastUpdate: item.ts,
            policyholderId: item.policyholderId,
            id: item.id,
            sequenceId: item.sequenceId,
            viewButton: item.status === EPolicyStatus.Referral && `/referral/${item.sequenceId}`,
            amendButton: getAmendURL(item),

            // additional columns
            effectiveFromDate: item.effectiveFromDate,
            effectiveToDate: item.effectiveToDate,
            issueDate: item.issueDate,
            policyType: i18n.t(item.policyType?.toLowerCase()),
            currency: item.currency,
            valueInsured: item.sumInsured,
            expiry,
            ipt: ipt || taxIpt,
            totalPayableByPolicyholder,
            upcoming,

            // BROKER ONLY
            insurer,
            percentAmount,
            brokerage,
            brokerPlatformFee: PolicyUtils.getValueByKey(item.totals, "Broker Platform Fee", "delta"),
            adminFee: PolicyUtils.getValueByKey(item.totals, "MTA Admin Fee", "delta"),
            adminPlatform: PolicyUtils.getValueByKey(item.totals, "Platform Adjustment Fee", "delta"),
            brokerageLessBroker: PolicyUtils.getValueByKey(item.totals, "Net Brokerage", "delta"),

            // INSURER ONLY
            totalDeductions: Number(totalDeductions),
            totalDeductionsPercent: Number(totalDeductionsPercent),
            netPremium: PolicyUtils.getValueByKey(item.totals, "Net Premium", "delta"),
            insurerPlatformFee: PolicyUtils.getValueByKey(item.totals, "Insurer Platform Fee", "delta"),
            netPremiumLessFeePlusIPT: PolicyUtils._getNetPremiumLessInsurerPlatformFeePlusIpt(item),

            // MGA ONLY
            mgaCommission: PolicyUtils.getValueByKey(item.totals, "MGA Commission", "delta"),
            mgaCommissionPercent: PolicyUtils.getSplitPercentage(product, "MGA Commission"),
            mgaLessPlatform: PolicyUtils.getValueByKey(item.totals, "NET MGA Commission", "delta"),
            mgaPlatformFee: PolicyUtils.getValueByKey(item.totals, "MGA Platform Fee", "delta"),
        };
    });

export function getAmendURL(policy: IPolicy): string | null {
    const isBound = policy.status === EPolicyStatus.Bound;
    const isCurrent = policy.version === policy.sequence;
    return isBound && isCurrent ? `/sureapp/insurenow/amend/${policy.sequenceId}/amend` : null;
}

// Additional columns would not be in the default column view
// These columns can be added by using Column Preferences

export const policiesTableColumns = [
    { id: "policyNoAndQuoteNo", isAdditional: false, header: ["policyNo", "quoteNo"] },
    { id: "email", isAdditional: false, header: ["email"] },
    { id: "policyholder", isAdditional: false, header: ["policyholder"] },
    { id: "effective", isAdditional: false, header: ["effective"] },
    { id: "renew", isAdditional: false, header: ["renew"] },
    { id: "product", isAdditional: false, header: ["product"] },
    { id: "mga", isAdditional: false, header: ["mga"], roles: [EIdentityType.Broker, EIdentityType.Blocksure, EIdentityType.Insurer] },
    { id: "agent", isAdditional: false, header: ["agent"], roles: [EIdentityType.Insurer, EIdentityType.MGA] },
    { id: "grossBrokerage", isAdditional: false, header: ["grossBrokerage"], roles: [EIdentityType.MGA] },
    { id: "premium", isAdditional: false, header: ["premium"] },
    { id: "accountBalance", isAdditional: false, header: ["accountBalance"] },

    { id: "lastUpdate", isAdditional: true, header: ["lastUpdate"] },
    { id: "policies", isAdditional: true, header: ["policies"] },
    { id: "effectiveFromDate", isAdditional: true, header: ["effectiveFromDate"] },
    { id: "effectiveToDate", isAdditional: true, header: ["effectiveToDate"] },
    { id: "issueDate", isAdditional: true, header: ["issueDate"] },
    { id: "policyType", isAdditional: true, header: ["policyType"] },
    { id: "currency", isAdditional: true, header: ["currency"] },
    { id: "valueInsured", isAdditional: true, header: ["valueInsured"] },
    { id: "expiry", isAdditional: true, header: ["expiry"] },
    { id: "ipt", isAdditional: true, header: ["ipt"] },
    { id: "totalPayableByPolicyholder", isAdditional: true, header: ["totalPayableByPolicyholder"] },

    // BROKER ONLY
    { id: "insurer", isAdditional: false, header: ["insurer"], roles: [EIdentityType.Broker, EIdentityType.Blocksure, EIdentityType.MGA] },
    { id: "percentAmount", isAdditional: true, header: ["brokerage", " %"], roles: [EIdentityType.Broker, EIdentityType.MGA] },
    { id: "brokerage", isAdditional: true, header: ["brokerage"], roles: [EIdentityType.Broker, EIdentityType.MGA] },
    { id: "brokerPlatformFee", isAdditional: true, header: ["brokerPlatformFee"], roles: [EIdentityType.Broker] },
    { id: "adminFee", isAdditional: true, header: ["adminFee"], roles: [EIdentityType.Broker] },
    { id: "adminPlatform", isAdditional: true, header: ["adminPlatform"], roles: [EIdentityType.Broker] },
    { id: "brokerageLessBroker", isAdditional: true, header: ["brokerageLessBroker"], roles: [EIdentityType.Broker] },

    // INSURER ONLY
    { id: "totalDeductionsPercent", isAdditional: true, header: ["totalDeductions", " %"], roles: [EIdentityType.Insurer] },
    { id: "totalDeductions", isAdditional: true, header: ["totalDeductions"], roles: [EIdentityType.Insurer] },
    { id: "netPremium", isAdditional: true, header: ["netPremium"], roles: [EIdentityType.Insurer] },
    { id: "insurerPlatformFee", isAdditional: true, header: ["insurerPlatformFee"], roles: [EIdentityType.Insurer] },
    { id: "netPremiumLessFeePlusIPT", isAdditional: true, header: ["netPremiumLessFeePlusIPT"], roles: [EIdentityType.Insurer] },

    // MGA ONLY
    { id: "mgaCommission", isAdditional: true, header: ["mgaCommission"], roles: [EIdentityType.MGA] },
    { id: "mgaCommissionPercent", isAdditional: true, header: ["mgaCommission", " %"], roles: [EIdentityType.MGA] },
    { id: "mgaLessPlatform", isAdditional: true, header: ["mgaLessPlatform"], roles: [EIdentityType.MGA] },
    { id: "mgaPlatformFee", isAdditional: true, header: ["mgaPlatformFee"], roles: [EIdentityType.MGA] },
];
