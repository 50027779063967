import React, { useState } from "react";
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { createGenerateClassName, StylesProvider, Theme, ThemeProvider } from "@material-ui/core/styles";
import "./styles.css";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "daterangecustom",
});

interface IProps {
    error?: string;
    disabled?: boolean;
    displayValue: string;
    label: string;
    locale: string;
    maxDate?: Date;
    name?: string;
    readOnly?: boolean;
    required?: boolean;
    theme: Theme;
    touched?: boolean;
    value: [Date, Date];
    onBlur?: () => void;
    onChange?: (value: [Date, Date]) => void;
}

const DateRangeCustom: React.FC<IProps> = React.memo(
    ({ error, disabled, displayValue, label, locale, maxDate, name, readOnly, required, theme, touched, value, onBlur, onChange }) => {
        const [open, setOpen] = useState(false);

        return (
            <StylesProvider generateClassName={generateClassName}>
                <ThemeProvider theme={theme}>
                    <InputLabel htmlFor={name} color="secondary">
                        {label}
                    </InputLabel>
                    <FormControl error={touched && Boolean(error)} fullWidth variant="outlined">
                        <OutlinedInput
                            autoComplete="off"
                            className={readOnly ? "read-only" : ""}
                            disabled={disabled}
                            fullWidth
                            id={name}
                            name={name}
                            readOnly={readOnly}
                            required={required}
                            value={displayValue}
                            startAdornment={
                                <DateRangePicker
                                    isOpen={open}
                                    locale={locale}
                                    maxDate={maxDate || new Date()}
                                    onChange={onChange}
                                    value={value}
                                    onCalendarClose={() => setOpen(false)}
                                />
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="date picker" size="small">
                                        <DateRangeIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            onBlur={onBlur}
                            onClick={() => !readOnly && setOpen(true)}
                        />
                        <FormHelperText>{touched ? error : ""}</FormHelperText>
                    </FormControl>
                </ThemeProvider>
            </StylesProvider>
        );
    }
);

export default DateRangeCustom;
