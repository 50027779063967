import React, { useState } from "react";
import { Collapse, IconButton, lighten, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { useStyles } from "./styles";
import HeaderRow from "../HeaderRow";
import { View } from "../Buttons";
import { createGenerateClassName, StylesProvider, Theme, ThemeProvider } from "@material-ui/core/styles";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "bodyrow",
});

interface ICell {
    count: number;
    size: number;
}

interface IProps {
    row: any;
    theme: Theme;
    headerGroups: any;
    view: boolean;
    isCollapseTable: boolean;
    cell: ICell[];
}

const BodyRow: React.FC<IProps> = ({ row, headerGroups, theme, view, isCollapseTable, cell }) => {
    const classes = useStyles(theme)();
    const [open, setOpen] = useState<boolean>(false);

    const firstRowCells = cell[0] ? row.cells.slice(0, cell[0].count) : row.cells;

    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <TableRow {...row.getRowProps()} hover>
                    {isCollapseTable && (
                        <TableCell align={"left"} className={classes.extrsSmall} style={{ width: 50 }}>
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                        </TableCell>
                    )}
                    {firstRowCells.map((cell, index) => {
                        const size = headerGroups[0].headers[index].size;

                        return (
                            <TableCell
                                {...cell.getCellProps()}
                                align={"left"}
                                className={size ? classes[size] : classes.cellWrap}
                                key={cell.column.id + cell.row.id}
                            >
                                {cell.render("Cell")}
                            </TableCell>
                        );
                    })}
                    {view && (
                        <TableCell align={"left"} className={classes.cellWrap}>
                            <View />
                        </TableCell>
                    )}
                </TableRow>
                {isCollapseTable && (
                    <TableRow hover style={{ borderBottom: open ? "inherit" : "none" }}>
                        <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0, background: lighten(theme.palette.divider, 0.7) }}
                            colSpan={firstRowCells.length + 1}
                        >
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                {cell &&
                                    cell.map((item, index) => {
                                        if (index > 0) {
                                            const secondRowCellsCount = cell.reduce((accumulator, currentValue, indexReducer) => {
                                                if (indexReducer < index) {
                                                    return accumulator + currentValue.count;
                                                }
                                                return accumulator;
                                            }, 0);

                                            const secondRowCells = row.cells.slice(secondRowCellsCount, secondRowCellsCount + item.count);

                                            const headerSizes = headerGroups[0].headers.slice(secondRowCellsCount, secondRowCellsCount + item.count);

                                            return (
                                                <Table
                                                    size="small"
                                                    key={
                                                        item.count +
                                                        item.size +
                                                        (secondRowCells[0] ? secondRowCells[0].column.id : 0) +
                                                        (secondRowCells[0] ? secondRowCells[0].row.id : 0)
                                                    }
                                                >
                                                    <TableHead>
                                                        {headerGroups.map((headerGroup) => {
                                                            const secondHeaderRows = headerGroup.headers.slice(
                                                                secondRowCellsCount,
                                                                secondRowCellsCount + item.count
                                                            );
                                                            return <HeaderRow theme={theme} headerGroup={headerGroup} headers={secondHeaderRows} />;
                                                        })}
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow hover>
                                                            {secondRowCells.map((cell, index) => {
                                                                const size = headerSizes[index].size;
                                                                return (
                                                                    <TableCell
                                                                        {...cell.getCellProps()}
                                                                        align={"left"}
                                                                        className={size ? classes[size] : classes.cellWrap}
                                                                    >
                                                                        {cell.render("Cell")}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            );
                                        }
                                        return null;
                                    })}
                            </Collapse>
                        </TableCell>
                    </TableRow>
                )}
            </ThemeProvider>
        </StylesProvider>
    );
};

export default React.memo(BodyRow);
