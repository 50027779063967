export const permissionsMock = [
    {
        id: "per_FgPxfyXvuPoUXu6NNEAFgv",
        name: "CLAIM_EDIT",
        category: "Insurance",
        service: "claim",
        description: "Modify claim data.",
    },
    {
        id: "per_FkEJhi4EWpcQgWrQybDWsJ",
        name: "CLAIM_READ",
        category: "Insurance",
        service: "claim",
        description: "View claim data.",
    },
];
