import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";
import { createGenerateClassName, StylesProvider, Theme, ThemeProvider } from "@material-ui/core/styles";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "selectcustom",
});

interface IProps {
    autoFocus?: boolean;
    error?: string;
    endAdornment?: any;
    disabled?: boolean;
    helperText?: string;
    label?: string;
    name?: string;
    options: { label: string; value: string; disabled?: boolean }[];
    readOnly?: boolean;
    required?: boolean;
    theme: Theme;
    touched?: boolean;
    type?: string;
    value: string;
    onBlur?: (e: React.ChangeEvent<any>) => void;
    onChange?: (e: React.ChangeEvent<any>) => void;
}

const SelectCustom: React.FC<IProps> = ({
    autoFocus,
    disabled,
    error,
    endAdornment,
    helperText,
    label,
    name,
    options,
    readOnly,
    required,
    theme,
    touched,
    value,
    onBlur,
    onChange,
}) => {
    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <InputLabel htmlFor={name} color="secondary">
                    {label}
                </InputLabel>
                <FormControl error={(touched) && Boolean(error)} fullWidth variant="outlined">
                    <Select
                        autoFocus={autoFocus}
                        className={readOnly ? "read-only" : ""}
                        disabled={disabled}
                        endAdornment={endAdornment}
                        fullWidth
                        name={name}
                        readOnly={readOnly}
                        required={required}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                    >
                        {options.map((option, index) => (
                            <MenuItem key={index} value={option.value} disabled={option.disabled}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{(touched) ? helperText || error : ""}</FormHelperText>
                </FormControl>
            </ThemeProvider>
        </StylesProvider>
    );
};

export default React.memo(SelectCustom);
