export interface ITableData {
    [key: string]: string | number | Date | boolean;
}

export interface ITableColumn {
    Header: string;
    accessor: string;
    isVisible?: boolean;
}

export enum ETableSize {
    extrsSmall = 50,
    small = 100,
    medium = 150,
    extraMedium = 250,
    large = 340,
}
