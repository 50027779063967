import { darken, lighten, makeStyles, Theme } from "@material-ui/core/styles";

const borderRadiusPx = 5;

export const useStyles = (theme: Theme) =>
    makeStyles(() => ({
        clickable: {
            cursor: "pointer",
            transition: "all 0.3s",
            "&:hover": {
                background: darken(theme.palette.primary.main, 0.03),
            },
        },
        grow: {
            flexGrow: 1,
        },
        iconBtn: {
            color: theme.palette.common.white,
            backgroundColor: "transparent",
        },
        item: {
            display: "flex",
            alignItems: "center",
            color: theme.palette.common.white,
            fontWeight: 300,
            letterSpacing: 1,
            padding: 4,
            [theme.breakpoints.up("sm")]: {
                padding: 6,
            },
            [theme.breakpoints.up("lg")]: {
                padding: 10,
            },
            "&:last-child": {
                paddingRight: 0,
            },
        },
        logo: {
            height: "2.5rem",
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        menuDivider: {
            width: `calc(100% - ${theme.spacing(6)}px)`,
            position: "absolute",
            "& > div": {
                borderTop: `1px solid ${lighten(theme.palette.primary.main, 0.3)}`,
            }
        },
        menuDividerBottom: {
            bottom: 0
        },
        menuDividerTop: {
            top: 0
        },
        menuItem: {
            color: `${theme.palette.common.white}!important`,
            fontWeight: 300,
            fontSize: 14,
            padding: `8px 24px!important`,
            "&:focus": {
                background: theme.palette.primary.main,
            },
        },
        menuList: {
            display: "flex",
            flexDirection: "column",
        },
        menuPaper: {
            background: theme.palette.primary.main,
            borderRadius: 0,
            boxShadow: "none",
        },
        noPadding: {
            padding: 0,
        },
        paper: {
            border: "none",
        },
        roundedBtn: {
            background: lighten(theme.palette.primary.main, 0.05),
            padding: theme.spacing(1, 2, 1, 3),
            borderRadius: `${borderRadiusPx}px !important`,
            fontWeight: 300,
            fontSize: 14,
            [theme.breakpoints.down("md")]: {
                maxWidth: "250px",
            },
        },
    }));
