import * as React from "react";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import { createStyles, lighten, makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { createGenerateClassName, StylesProvider, ThemeProvider } from "@material-ui/core";
import { PageContainer } from "..";
import { useTranslation } from "react-i18next";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "page-not-found",
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            height: "80vh",
        },
        icon: {
            width: `${theme.spacing(20)}px!important`,
            height: `${theme.spacing(20)}px!important`,
            color: lighten(theme.palette.primary.main, 0.9),
        },
    })
);

interface IProps {
    theme: Theme;
}

export const PageNotFoundPage: React.FC<IProps> = ({ theme }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <PageContainer theme={theme} title="">
                    <Grid container direction="column" justify="center" alignItems="center" classes={{ root: classes.wrapper }}>
                        <FindInPageIcon classes={{ root: classes.icon }} />
                        <Typography>404 {t("pageNotFound")}</Typography>
                    </Grid>
                </PageContainer>
            </ThemeProvider>
        </StylesProvider>
    );
};
