import { ETypes, ISearchResponse } from "../models";

export const searchPolicyMock: ISearchResponse = {
    id: "seq_123",
    modifiedDate: "2020-09-17T17:18:42.534Z",
    tags: {
        id: "pol_123",
        productName: "Product Name",
        status: "Bound",
        inceptionDate: "2020-09-17T17:17:45.202Z",
        "totalPayable.fullTerm": "0.00",
    },
    type: ETypes.policy,
};

export const searchPolicyholderMock: ISearchResponse = {
    id: "pho_123",
    modifiedDate: "2020-09-17T17:18:42.534Z",
    tags: {
        id: "pho_123",
        town: "Harrogate",
        firstName: "Dim",
        lastName: "Bat",
        primaryEmailAddress: "ranvir.saggu+cancel@blocksure.com",
        primaryPhoneNumber: "0777777777",
        title: "Dr",
    },
    type: ETypes.policyholder,
};

export const searchClaimMock: ISearchResponse = {
    id: "clm_123",
    modifiedDate: "2020-09-17T17:18:42.534Z",
    tags: {
        id: "clm_123",
        category: "Accident",
        productName: "More Sure Temp Car Insurance",
        creationDate: "2020-09-14T11:22:21.935Z",
        dateOfIncident: "2020-09-14T11:22:21Z",
        claimAmount: "300",
    },
    type: ETypes.claim,
};

export const searchRfqMock: ISearchResponse = {
    id: "pol_123",
    modifiedDate: "2020-09-17T17:18:42.534Z",
    tags: {
        productName: "More Sure Temp Car Insurance",
        inceptionDate: "2020-09-14T11:22:21.935Z",
        status: "RFQ",
    },
    type: ETypes.rfq,
};
