import React from "react";
import { useTranslation } from "react-i18next";
import { createGenerateClassName, Link as LinkButton, StylesProvider, Theme, ThemeProvider } from "@material-ui/core";
import { generateConfluenceSpace, generateConfluenceLink } from '@blocksure/blocksure-core/dist/src/utilities/generateConfluenceData';

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "help-faqs",
});

interface IHelpAndFAQs {
    color?: string;
    locale: string;
    theme: Theme;
    translate?: string;
}

const HelpAndFAQs: React.FC<IHelpAndFAQs> = ({ color, locale, theme, translate }) => {
    const { t } = useTranslation();
    const value = generateConfluenceSpace(locale);

    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <LinkButton style={{ color }} target="_blank" href={generateConfluenceLink(value)} rel="noopener">
                    {translate || t("helpAndFaqs")}
                </LinkButton>
            </ThemeProvider>
        </StylesProvider>
    );
};

export default HelpAndFAQs;
