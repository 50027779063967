export const authDetailsMock = {
    identityType: "Broker",
    party: "C=GB,L=London,O=TestBroker",
    permissions: [],
    roles: ["Super"],
    username: "test@test.com",
};

export const userDetailsMock = {
    roles: {
        category: "Common Roles",
        description: "Access to administrative functions.",
        id: "rol_84TJSGQyiRCw872hZjizT4",
        modifiedBy: "INIT",
        name: "Super",
    },
    id: "usr_123",
    email: "test@test.com",
    firstName: "Roy",
    lastName: "Day",
};
