import { useEffect, useState } from "react";
import InfraApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/InfraApiClient";

const Parties = [
    "C=GB,L=London,O=Blocksure",
    "C=GB,L=London,O=TestBroker",
    "C=GB,L=London,O=TestInsurer",
    "C=GB,L=London,O=MoreSure",
    "C=GB,L=London,O=TestMGA",
];

export const useParties = (namespacedLocalStorage) => {
    const [parties, setParties] = useState(null);

    useEffect(() => {
        let isMount = true;
        const fetchParties = async () => {
            const infraApiClient = new InfraApiClient(namespacedLocalStorage);

            try {
                const { networkMap } = await infraApiClient.listNetwork();
                const parties = networkMap.map((it) => alphabetise(it.legalIdentitiesAndCerts[0].party.name));

                if (!isMount) return;

                setParties(parties);
            } catch (error) {
                if (!isMount) return;
                console.warn("error", error.message);
                setParties(Parties);
            }
        };

        fetchParties();

        return () => {
            isMount = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return parties;
};

// FIXME could be removed when PLAT-2452 will be completed
function alphabetise(value: string) {
    return value.replace(/ /g, "").split(",").sort().join(",");
}
