export const universalPolicyholderSchemaMock = {
    "ui:order": ["personal", "contact", "address"],
    type: "object",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
        personal: {
            type: "object",
            properties: {
                title: {
                    type: "string",
                },
                firstName: {
                    type: "string",
                },
                lastName: {
                    type: "string",
                },
                dateOfBirth: {
                    type: "string",
                    format: "alt-date",
                },
            },
            required: ["title", "firstName", "lastName", "dateOfBirth"],
            "ui:order": ["title", "firstName", "lastName", "dateOfBirth"],
        },
        contact: {
            type: "object",
            properties: {
                primaryEmailAddress: {
                    type: "string",
                    format: "email",
                },
                primaryPhoneNumber: {
                    type: "string",
                },
                contactMethod: {
                    type: "string",
                },
            },
            required: ["primaryEmailAddress", "primaryPhoneNumber"],
            "ui:order": ["primaryEmailAddress", "primaryPhoneNumber", "contactMethod"],
        },
        address: {
            type: "object",
            properties: {
                line1: {
                    type: "string",
                },
                line2: {
                    type: "string",
                },
                line3: {
                    type: "string",
                },
                line4: {
                    type: "string",
                },
                subzone: {
                    type: "string",
                },
                zone: {
                    type: "string",
                },
                country: {
                    type: "string",
                },
                postcode: {
                    type: "string",
                },
            },
            required: ["subzone", "country", "postcode"],
            "ui:order": ["line1", "line2", "line3", "line4", "subzone", "zone", "country", "postcode"],
        },
    },
};

export const universalPolicyholderJPSchemaMock = {
    "ui:order": ["personal", "contact", "address"],
    type: "object",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
        personal: {
            type: "object",
            properties: {
                title: {
                    type: "string",
                },
                firstName: {
                    type: "string",
                },
                lastName: {
                    type: "string",
                },
                dateOfBirth: {
                    type: "string",
                    format: "alt-date",
                },
                firstNameFurigana: {
                    type: "string",
                },
                lastNameFurigana: {
                    type: "string",
                },
            },
            required: ["firstName", "lastName"],
            "ui:order": ["title", "lastName", "firstName", "lastNameFurigana", "firstNameFurigana", "dateOfBirth"],
        },
        contact: {
            type: "object",
            properties: {
                primaryEmailAddress: {
                    type: "string",
                    format: "email",
                },
                primaryPhoneNumber: {
                    type: "string",
                },
                contactMethod: {
                    type: "string",
                },
            },
            required: ["primaryEmailAddress"],
            "ui:order": ["primaryEmailAddress", "primaryPhoneNumber", "contactMethod"],
        },
        address: {
            type: "object",
            properties: {
                line1: {
                    type: "string",
                },
                line2: {
                    type: "string",
                },
                line3: {
                    type: "string",
                },
                line4: {
                    type: "string",
                },
                subzone: {
                    type: "string",
                },
                zone: {
                    type: "string",
                },
                country: {
                    type: "string",
                },
                postcode: {
                    type: "string",
                },
            },
            required: ["line1", "line2", "line3", "subzone", "zone", "country", "postcode"],
            "ui:order": ["postcode", "zone", "subzone", "line4", "line3", "line2", "line1", "country"],
        },
    },
};
