export const submissionSchemeMock = {
    $schema: "http://json-schema.org/draft-07/schema#",
    "ui:order": ["aboutYou", "quote", "moreAbout", "furtherDetails", "declaration", "property", "payment", "summary"],
    properties: {
        aboutYou: {
            type: "object",
            title: "About you",
            "ui:help":
                "You must take reasonable care not to make a misrepresentation to us or the insurer. This means that all the answers you give and statements you make must be honest and accurate. If you deliberately, recklessly or carelessly misinform the insurers, this could affect the settlement of any claim you may make.",
            required: ["address", "dateOfBirth", "employmentStatus", "primaryEmailAddress"],
            "ui:order": ["address", "dateOfBirth", "employmentStatus", "primaryEmailAddress"],
            properties: {
                address: {
                    $ref: "def.json#/aboutYou.address",
                    "ui:field": "addressField",
                    "ui:placeholder": "Enter Postcode",
                },
                dateOfBirth: {
                    $ref: "def.json#/aboutYou.dateOfBirth",
                    "ui:widget": "alt-date2",
                    "ui:options": {
                        yearsRange: [-18, -120],
                    },
                },
                employmentStatus: {
                    $ref: "def.json#/aboutYou.employmentStatus",
                },
                primaryEmailAddress: {
                    $ref: "def.json#/aboutYou.primaryEmailAddress",
                    "ui:placeholder": "Enter Email",
                },
            },
            definitions: {},
            description: "Each \\* indicates a field required to produce a quote.",
            "fm:validation": [
                {
                    name: "minAge",
                    rule: "ageIsAtLeast(formData.dateOfBirth, 18)",
                    field: "dateOfBirth",
                    message: "You must be at least 18 years of age to buy a policy.",
                },
            ],
            "fm:collectPolicyholderData": true,
        },
        moreAbout: {
            type: "object",
            title: "More about you",
            required: ["title", "firstName", "lastName", "primaryPhoneNumber", "contactMethod"],
            "ui:order": ["title", "firstName", "lastName", "primaryPhoneNumber", "contactMethod", "otherAddress", "marketingApproval"],
            "fm:country": "GB",
            properties: {
                title: {
                    $ref: "def.json#/moreAbout.title",
                    classNames: "col-sm-2 col-xs-12 col-sm-padding col-sm-padding-first",
                },
                lastName: {
                    $ref: "def.json#/moreAbout.lastName",
                    classNames: "col-sm-5 col-xs-6 col-sm-padding col-sm-padding-last",
                },
                firstName: {
                    $ref: "def.json#/moreAbout.firstName",
                    classNames: "col-sm-5 col-xs-6 col-sm-padding",
                },
                otherAddress: {
                    "ui:field": "addressField",
                    "ui:placeholder": "Lookup Postcode",
                },
                contactMethod: {
                    $ref: "def.json#/moreAbout.contactMethod",
                },
                marketingApproval: {
                    $ref: "def.json#/moreAbout.marketingApproval",
                    "fm:local": true,
                    "ui:field": "yesno",
                },
                primaryPhoneNumber: {
                    $ref: "def.json#/moreAbout.primaryPhoneNumber",
                    "ui:field": "phoneNumberField",
                    "ui:placeholder": "Enter Mobile",
                },
            },
            dependencies: {
                contactMethod: {
                    oneOf: [
                        {
                            required: ["otherAddress"],
                            properties: {
                                otherAddress: {
                                    $ref: "def.json#/moreAbout.otherAddress",
                                },
                                contactMethod: {
                                    enum: ["postDifferentAddress"],
                                },
                            },
                        },
                        {
                            properties: {
                                contactMethod: {
                                    enum: ["email", "phone", "postHomeAddress"],
                                },
                            },
                        },
                    ],
                },
            },
            "fm:submitQuote": true,
            "fm:policyholderData": true,
        },
    },
};
