export interface ISearchResponse {
    id: string;
    modifiedDate: string;
    tags: { [key: string]: string };
    type: ETypes;
}

export enum ETypes {
    claim = "claim",
    individual = "Individual",
    policy = "policy",
    policyholder = "policyholder",
    rfq = "rfq",
}
