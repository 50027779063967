import React from "react";
import { Box, Typography } from "@material-ui/core";
import { CheckBox, Error } from "@material-ui/icons";
import { useStyles } from "./styles";
import { createGenerateClassName, StylesProvider, Theme, ThemeProvider } from "@material-ui/core/styles";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "iconfield",
});

interface IProps {
    text: string;
    error: string;
    theme: Theme;
}

const IconField: React.FC<IProps> = ({ text, error, theme }) => {
    const classes = useStyles();

    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <div>
                    <Box display="flex" alignItems="center" marginBottom={1}>
                        {error ? <Error color="error" fontSize="small" /> : <CheckBox className={classes.successIcon} fontSize="small" />}
                        <Typography variant="body2" className={classes.labelMarginLeft} color={error ? "initial" : "secondary"}>
                            {text}
                        </Typography>
                    </Box>
                </div>
            </ThemeProvider>
        </StylesProvider>
    );
};

export default IconField;
