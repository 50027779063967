import React, { ChangeEvent, useState } from "react";
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DatePicker from "react-date-picker";
import { createGenerateClassName, StylesProvider, Theme, ThemeProvider } from "@material-ui/core/styles";
import "./styles.css";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "datepickercustom",
});

interface IProps {
    alignRight?: boolean;
    error?: string;
    disabled?: boolean;
    displayValue: string;
    label: string;
    locale: string;
    minDate?: Date;
    maxDate?: Date;
    name?: string;
    readOnly?: boolean;
    required?: boolean;
    theme: Theme;
    touched?: boolean;
    value: Date;
    onBlur?: (e: React.ChangeEvent<any>) => void;
    onChange?: (value: Date, event: ChangeEvent<HTMLInputElement>) => void;
}

const DatePickerCustom: React.FC<IProps> = React.memo(
    ({
        alignRight,
        error,
        disabled,
        displayValue,
        label,
        locale,
        minDate,
        maxDate,
        name,
        readOnly,
        required,
        theme,
        touched,
        value,
        onBlur,
        onChange,
    }) => {
        const [open, setOpen] = useState(false);
        const renderPicker = () => (
            <DatePicker
                className={alignRight ? "date-picker-right-align" : ""}
                isOpen={open}
                locale={locale}
                minDate={minDate}
                maxDate={maxDate || new Date()}
                onChange={onChange}
                value={value}
                onCalendarClose={() => setOpen(false)}
            />
        );

        return (
            <StylesProvider generateClassName={generateClassName}>
                <ThemeProvider theme={theme}>
                    <InputLabel htmlFor={name} color="secondary">
                        {label}
                        {required ? "*" : ""}
                    </InputLabel>
                    <FormControl error={touched && Boolean(error)} fullWidth variant="outlined">
                        <OutlinedInput
                            autoComplete="off"
                            className={readOnly ? "read-only" : ""}
                            disabled={disabled}
                            fullWidth
                            id={name}
                            name={name}
                            readOnly={readOnly}
                            required={required}
                            value={displayValue || ""}
                            startAdornment={!alignRight && renderPicker()}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="date picker" size="small">
                                        <DateRangeIcon />
                                    </IconButton>
                                    {alignRight && renderPicker()}
                                </InputAdornment>
                            }
                            onBlur={onBlur}
                            onClick={() => !readOnly && setOpen(true)}
                        />
                        <FormHelperText>{touched ? error : ""}</FormHelperText>
                    </FormControl>
                </ThemeProvider>
            </StylesProvider>
        );
    }
);

export default DatePickerCustom;
