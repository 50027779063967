import "regenerator-runtime/runtime";
import { BehaviorSubject } from "rxjs";
import { namespacedLocalStorage } from "./named-local-storage.service";

const locale = namespacedLocalStorage.getItem("locale") || detectLanguage();
const localeSubject = new BehaviorSubject(locale);
const i18nSubject = new BehaviorSubject(null);

export const localeService = {
    default: "en-GB",
    setLocale,
    locale$: localeSubject.asObservable(),
    get locale() {
        return localeSubject.value;
    },
    getLanguage() {
        return localeSubject.value.split("-")[0];
    },
    i18nLoader$: i18nSubject.asObservable(),
    onLoadI18: (val) => i18nSubject.next(val),
    get i18nReady() {
        return i18nSubject.value;
    },
};

function detectLanguage() {
    let lang = window.navigator.languages ? window.navigator.languages[0] : null;
    lang = lang || window.navigator.language;

    return lang;
}

function setLocale(value) {
    if (localeService.locale !== value) {
        namespacedLocalStorage.setItem("locale", value);
        localeSubject.next(value);
    }
}
