import { signInService } from "../services";

type FeatureFlag = {
    menuCSVImporter?: boolean;
}

const FeatureFlags: Record<string, FeatureFlag> = {
    "alexander.luchitskiy@blocksure.com": {
        menuCSVImporter: true,
    },
    "chris.hamilton@blocksure.com": {
        menuCSVImporter: true,
    },
    "denis.matwiejuk@blocksure.com": {
        menuCSVImporter: true,
    },
};

export function isFeatureEnabledForUser(flagName: keyof FeatureFlag): boolean {
    const authDetails = signInService.loadAuthDetails();
    return FeatureFlags[authDetails.username]?.[flagName] || false;
}
