import { IProduct } from "../models";

export class ProductUtils {
    static _formatParty = (party) => {
        if (!party) return null;
        const tokens = party.split(/[,=]/);
        return tokens[tokens.indexOf("O") + 1];
    };

    static isSameParties = (product: IProduct) => {
        const parties = Object.values(product.sections).map((it) => it.parties && it.parties.join("|"));
        return !parties.find((it) => it !== parties[0]);
    };

    static isSingleSection = (product: IProduct) => {
        return Object.keys(product.sections).length === 1;
    };
}
