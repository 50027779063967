import {EAttachmentTransaction, IAttachment} from "./attachment.model";
import {ELocale} from "./locale.model";
import {IBrokerage} from "./policy.model";

export interface IProduct {
    adminFeePayer: "Broker" | "Policyholder",
    approvals: { [key: string]: IPrrovalable };
    blocksureParty: string;
    brokerBind?: EBindCondition;
    cancellation?: {
        authorisedParties?: string[];
        cancelTypes?: string[];
        reasons?: string[];
    };
    coolingOffPeriod?: string; // 'P14D'
    country: string;
    created?: string;
    creditPeriod: string | null;
    description: string;
    dynamicDocumentTemplates: IAttachment[];
    hiddenAttachments: IAttachment[];
    id: string;
    idDraft?: number;
    idRanges: IIdRanges;
    insuranceType: string;
    language: ELocale;
    lastEditedBy: string;
    lastEditedAt: string;
    lineOfBusiness?: "NonLife" | "Life" | "Health" | "Combined";
    name: string;
    notary: string;
    notifications: INotification[];
    owner: string;
    partiesAndRoles: {
        Broker: string;
        Insurer: string;
        MGA?: string;
    };
    payment: {
        currency: string;
        additionalCharges: IPlatformFee;
        cancellationFee: string;
        cancellationFeePayees: IPlatformFee;
        mtaFee: string;
        mtaFeePayees: IPlatformFee;
        paymentFeePayees: IPlatformFee;
        premiumSplits: IPrimiumSplit;
    };
    paymentGateway: string;
    paymentModel?: {
        premiumSplit: {
            Brokerage: {
                percentAmount: string;
            };
        };
    };
    policyholderBind?: EBindCondition;
    quoteValidity: string;
    refundMode: "Enabled" | "Disabled";
    renewal: {
        mode: "Enabled" | "Disabled",
        quoteBeforeExpiry: null,
    },
    sectionDisplayOrder?: string[];
    sections?: ISections;
    state?: "Active" | "Draft" | "New" | "Terminated";
    staticAttachments: IAttachment[];
    ts?: string;
    validPayFrequencies: string[]; // ['P14D', 'P1M', 'P1Y']
    validPolicyTerms: string[];
    version: number;
}

interface IEndorsements {
    [key: string]: {
        contractText: string;
        coverAdjustment: number;
        premiumAdjustment: number;
    };
}

interface IIssues {
    [key: string]: { endorsements: IEndorsements };
}

export enum ERatingTypes {
    Fixed = "Fixed",
    Excel = "Excel",
}

interface IRating {
    party: string;
    type: ERatingTypes;
    params: {
        premium: string;
        sumInsured: string;
    };
}

type IPrimiumSplit = {
    [key in keyof IBrokerage]: IPayeeAmount & { payees: { [key in keyof IBrokerage]: IPayeeAmount } };
}

interface IGeneral {
    id: string;
    issues: IIssues;
    name: string;
    parties: string[]; // ["C=GB,L=London,O=TestBroker", "C=GB,L=London,O=MoreSure"]
    payment: {
        additionalCharges: { [key in keyof IBrokerage]: IPayeeAmount & { payees: { [key in keyof IBrokerage]: IPayeeAmount } } };
        premiumSplits: IPrimiumSplit;
    };
    rating: IRating;
}

interface ISections {
    [key: string]: IGeneral;
}

export enum EApprovalStatus {
    Approved = "Approved",
    AwaitingYourApproval = "AwaitingApp",
    InProduction = "inProd",
    Pending = "Pending",
    Rejected = "Rejected",
}

export interface IPrrovalable {
    comments: string;
    party: string;
    status: EApprovalStatus;
    ts: string;
}

export enum EBindCondition {
    NotAllowed = "NotAllowed",
    WithPayment = "WithPayment",
    WithoutPayment = "WithoutPayment",
}

interface IIdRange {
    start: string;
    end: string;
    prefix?: string;
    suffix?: string;
}

interface IIdRanges {
    claims: IIdRange;
    policies: IIdRange;
}

type ProductRole = "Blocksure" | "Insurer" | "Broker" | "MGA";

interface IPayeeAmount {
    payer?: ProductRole;
    payee?: ProductRole;
    amount?: string;
}

interface IPlatformFee {
    [key: string]: IPayeeAmount;
}

export interface INotification {
    document: string;   // "PolicyEmail"
    relativeTo: null;
    offsets: string[];   // ["PT30M"]
    transaction: EAttachmentTransaction;
    documentsAttached: string[];    // ["PolicyTemplate"]
}
