import React from "react";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography/Typography";
import { Grid, Hidden, IconButton, lighten } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CloseIcon from "@material-ui/icons/Close";
import { createGenerateClassName, StylesProvider, Theme, ThemeProvider } from "@material-ui/core/styles";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "messagebox",
});

const useStyles = makeStyles((theme) => ({
    error: {
        color: theme.palette.error.main,
        background: lighten(theme.palette.error.main, 0.95),
        padding: theme.spacing(2),
        margin: 0,
        border: `1px solid ${theme.palette.error.main}`,
    },
    warning: {
        color: theme.palette.warning.main,
        background: lighten(theme.palette.warning.main, 0.95),
        padding: theme.spacing(2),
        margin: 0,
        border: `1px solid ${theme.palette.warning.main}`,
    },
    important: {
        color: theme.palette.primary.main,
        background: lighten(theme.palette.primary.main, 0.95),
        padding: theme.spacing(2),
        margin: 0,
        border: `1px solid ${theme.palette.primary.main}`,
    },
}));

interface IProps {
    message: string;
    theme: Theme;
    variant: "important" | "error" | "warning";
    onClose?: () => void;
}

export const MessageBox: React.FC<IProps> = ({ message, theme, variant, onClose }) => {
    const classes = useStyles();

    if (!message) {
        return null;
    }

    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <Box my={2} p={2} className={classes[variant]} data-testid={`${variant}-message`}>
                    <Grid container alignItems="center" spacing={2} wrap="nowrap">
                        <Grid item>
                            <ErrorOutlineIcon style={{ verticalAlign: "middle" }} />
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body1">{message}</Typography>
                        </Grid>
                        <Hidden xsUp={!onClose}>
                            <Grid item>
                                <IconButton color="inherit" onClick={onClose} size="small">
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Box>
            </ThemeProvider>
        </StylesProvider>
    );
};
