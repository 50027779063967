import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { useStyles } from "./styles";
import { createGenerateClassName, StylesProvider, Theme, ThemeProvider } from "@material-ui/core/styles";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "footerrow",
});

interface IProps {
    footerGroup: any;
    footers: any[];
    theme: Theme;
}

const FooterRow: React.FC<IProps> = ({ footerGroup, footers, theme }) => {
    const classes = useStyles(theme)();

    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <TableRow {...footerGroup.getHeaderGroupProps()}>
                    {footers.map((column) => {
                        return (
                            <TableCell align="left" className={classes[column.size]}>
                                <div>{column.render("Footer")}</div>
                            </TableCell>
                        );
                    })}
                </TableRow>
            </ThemeProvider>
        </StylesProvider>
    );
};

export default FooterRow;
