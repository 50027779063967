export interface IPermission {
    category: string;
    description: string;
    id: string;
    name: string;
    service: string;
}

export interface IRolePermission {
    category: string;
    description: string;
    id: string;
    modifiedBy: string;
    name: string;
    permissions: IPermission[];
}

export const hiddenRoles = ["Blocksure Roles", "Special Roles", "Service Accounts"];

// SUR-151
// Revert SUR-142 INVOICE_EDIT / INVOICE_READ
export const isNotHiddenPermissions = (permission: IPermission) => !["SYSTEM_AUTH" /**, 'INVOICE_EDIT', 'INVOICE_READ' */].includes(permission.name);
