import { ELocale, IProduct, ERatingTypes, EAttachmentTransaction, IAttachment } from "../models";

export const productMock: IProduct = {
    adminFeePayer: "Policyholder",
    approvals: {},
    blocksureParty: "C=GB,L=London,O=Blocksure",
    cancellation: {
        authorisedParties: ["C=GB,L=London,O=TestBroker"],
        cancelTypes: ["Inception", "Custom"],
        reasons: ["Reason 1", "Reason 2"],
    },
    created: "2020-07-07T11:25:47.852Z",
    coolingOffPeriod: "P14D",
    country: "GB",
    creditPeriod: null,
    description: "Product Description",
    dynamicDocumentTemplates: [{
        contentType: "text/html",
        description: "",
        location: "loc_123",
        modifiedDate: "2021-01-11T12:19:48.839Z",
        name: "PolicyholderEmail",
        transactions: [EAttachmentTransaction.Register]
    }, {
        contentType: "text/html",
        description: "",
        location: "loc_1234",
        modifiedDate: "2021-01-11T12:19:48.839Z",
        name: "PolicyEmail",
        transactions: [EAttachmentTransaction.Quote]
    }],
    hiddenAttachments: [{
        contentType: "application/json",
        description: "",
        location: "loc_123_def_json",
        modifiedDate: "2022-01-18T12:49:41.37Z",
        name: "def.json",
        transactions: []
    }],
    id: "prd_123",
    idRanges: {
        claims: {
            start: "000000",
            end: "999999",
            prefix: "CLA",
        },
        policies: {
            start: "000000",
            end: "999999",
            prefix: "BAL",
        },
    },
    insuranceType: "Product Type",
    lastEditedBy: "Engineering Team",
    lastEditedAt: "2020-07-07T11:25:47.852Z",
    language: ELocale.en,
    lineOfBusiness: "NonLife",
    name: "Product Name",
    notary: "C=GB,L=London,O=BlocksureNotary",
    notifications: [{
        document: "PolicyholderEmail",
        relativeTo: null,
        offsets: null,
        transaction: EAttachmentTransaction.Register,
        documentsAttached: ["IPID.pdf"]
    }, {
        document: "PolicyEmail",
        relativeTo: null,
        offsets: null,
        transaction: EAttachmentTransaction.Quote,
        documentsAttached: ["IPID.pdf"]
    }],
    owner: "Ranvir Saggu",
    partiesAndRoles: {
        Broker: "C=GB,L=London,O=TestBroker",
        Insurer: "C=GB,L=London,O=TestInsurer",
    },
    payment: {
        currency: "GBP",
        additionalCharges: {
            IPT: { payer: "Broker", payee: "Insurer", amount: "12%" },
        },
        cancellationFee: "1.00",
        cancellationFeePayees: {
            "Broker Cancellation Fee": { payer: null, payee: "Broker", amount: null },
            "Platform Cancellation Fee": { payer: null, payee: "Blocksure", amount: "25%" },
        },
        mtaFee: "1.00",
        mtaFeePayees: {
            "Broker Adjustment Fee": { payer: null, payee: "Broker", amount: null },
            "Platform Adjustment Fee": { payer: null, payee: "Blocksure", amount: "25%" },
        },
        paymentFeePayees: {
            "Broker Payment Gateway Fee": { payer: "Broker", payee: "Blocksure", amount: "100%" },
        },
        premiumSplits: {
            Brokerage: {
                amount: "30%",
                payee: "Broker",
                payees: {
                    "Broker Platform Fee": { payer: "Broker", payee: "Blocksure", amount: "25%" },
                    "Net Brokerage": { payer: null, payee: "Broker", amount: null },
                },
                payer: "Insurer"
            }
        }
    },
    paymentGateway: "",
    quoteValidity: "P1M",
    staticAttachments: [{
        contentType: "application/pdf",
        description: "",
        location: "loc_345",
        modifiedDate: "2021-01-11T12:19:48.839Z",
        name: "IPID.pdf",
        transactions: [EAttachmentTransaction.Register]
    }],
    refundMode: "Enabled",
    renewal: {
        mode: "Disabled",
        quoteBeforeExpiry: null,
    },
    sectionDisplayOrder: ["pedalCycles", "possessions"],
    sections: {
        pedalCycles: {
            id: "sec_123",
            issues: {},
            name: "Pedal Cycles",
            parties: ["C=GB,L=London,O=TestBroker"],
            payment: {
                additionalCharges: {},
                premiumSplits: {
                    Brokerage: {
                        amount: "7500",
                        payee: "Broker",
                        payees: {
                            "Broker Platform Fee": { payer: "Broker", payee: "Blocksure", amount: "317" },
                            "Net Brokerage": { payer: null, payee: "Broker", amount: null },
                        },
                        payer: "Insurer",
                    },
                    "Net Premium": {
                        amount: "7500",
                        payee: "Insurer",
                        payees: {
                            "Insurer Platform Fee": { payer: "Insurer", payee: "Blocksure", amount: "739" },
                            "Net Net Premium": { payer: "Broker", payee: "Insurer", amount: null },
                        },
                        payer: "Insurer",
                    },
                },
            },
            rating: { party: "C=GB,L=London,O=TestBroker", type: ERatingTypes.Fixed, params: { premium: "1000.00", sumInsured: "1000000.00" } },
        },
        possessions: {
            id: "sec_456",
            issues: {},
            name: "Possessions",
            parties: ["C=GB,L=London,O=TestBroker"],
            payment: {
                additionalCharges: {},
                premiumSplits: {
                    Brokerage: {
                        amount: "7500",
                        payee: "Broker",
                        payees: {
                            "Broker Platform Fee": { payer: "Broker", payee: "Blocksure", amount: "317" },
                            "Net Brokerage": { payer: null, payee: "Broker", amount: null },
                        },
                        payer: "Insurer",
                    },
                    "Net Premium": {
                        amount: "7500",
                        payee: "Insurer",
                        payees: {
                            "Insurer Platform Fee": { payer: "Insurer", payee: "Blocksure", amount: "739" },
                            "Net Net Premium": { payer: "Broker", payee: "Insurer", amount: null },
                        },
                        payer: "Insurer",
                    },
                },
            },
            rating: { party: "C=GB,L=London,O=TestBroker", type: ERatingTypes.Fixed, params: { premium: "1000.00", sumInsured: "1000000.00" } },
        },
    },
    state: "Active",
    validPayFrequencies: ["PT48H", "P1M", "P1Y"],
    validPolicyTerms: ["PT48H", "P1M", "P1Y"],
    version: 1,
};

export const attachmentMock: IAttachment = {
    blobHash: null,
    contentType: "text/plain",
    description: "Attachment Description",
    location: null,
    modifiedDate: null,
    name: "Attachment name",
    transactions: [EAttachmentTransaction.Bind, EAttachmentTransaction.Cancellation],
    url: null,
}

export const defMock = {
    "aboutYou.address": {
        "type": "object",
        "title": "Home address"
    },
    "aboutYou.address.line1": {
        "type": "string",
        "title": "Room No, Floor No"
    },
    "aboutYou.address.line2": {
        "type": "string",
        "title": "Street Name & Number / Building Name"
    },
    "aboutYou.address.line3": {
        "type": "string",
        "title": "--"
    },
    "aboutYou.address.line4": {
        "type": "string",
        "title": "--"
    },
    "aboutYou.address.subzone": {
        "type": "string",
        "title": "City"
    },
    "aboutYou.address.zone": {
        "type": "string",
        "title": "County"
    },
    "aboutYou.address.country": {
        "type": "string",
        "title": "Country"
    },
    "aboutYou.address.postcode": {
        "type": "string",
        "title": "Postcode"
    },
    "aboutYou.dateOfBirth": {
        "type": "string",
        "title": "Date of birth",
        "format": "alt-date"
    },
    "aboutYou.employmentStatus": {
        "type": "string",
        "title": "Employment status",
        "enum": ["EmployedFullTime", "EmployedPartTime", "SelfEmployed", "DWPNonReferral", "DWPReferral", "UnderGraduateStudent", "PostGraduateStudent", "Retired"],
        "enumNames": ["Employed - Full Time", "Employed - Part Time", "Self -Employed", "DWP Non-Referral", "DWP (Referred)", "Under Graduate Student", "Post Graduate Student", "Retired"]
    },
    "aboutYou.primaryEmailAddress": {
        "title": "Email",
        "type": "string",
        "format": "email"
    },
    "furtherDetails.contents": {
        "title": "Total Contents Insured",
        "description": "The value of everything you need to insure this includes contents in your property.",
        "type": "string",
        "enum": ["5000", "7500", "10000", "15000", "20000", "25000", "30000"]
    },
    "furtherDetails.dwelling": {
        "title": "What kind of property is this?",
        "default": "FLAT GROUND FLOOR OR BASEMENT",
        "type": "string"
    },
    "furtherDetails.specifiedPersonalPossessions.item": {
        "type": "string",
        "title": "Type",
        "enum": ["Phone", "Luggage / Handbag", "Clothing", "Spectacles", "Sports Equipment", "Jewellery", "Watches", "Portable Computer Equipment", "Binoculars", "Telescope", "Portable Musical Equipment", "Other (not High Risk)"]
    },
    "furtherDetails.specifiedPersonalPossessions.value": {
        "type": "number",
        "title": "Value",
        "minimum": 1500,
        "maximum": 7500
    },
    "furtherDetails.specifiedPersonalPossessions.description": {
        "title": "Description",
        "type": "string"
    },
    "furtherDetails.unspecifiedPersonalPossessions.value": {
        "title": "What is the value of the remaining [unspecified personal possessions](attachment:PolicyWording.pdf) that are each under £1,500? (Note: total should be between £1,000 and £10,000)",
        "type": "number",
        "minimum": 1000,
        "maximum": 10000
    },
    "furtherDetails.pedalCycles.item": {
        "title": "Name",
        "type": "string"
    },
    "furtherDetails.pedalCycles.value": {
        "title": "Value",
        "type": "number",
        "minimum": 50,
        "maximum": 2000
    },
    "furtherDetails.accidentalDamageCover": {
        "title": "Do you want accidental damage cover?",
        "type": "boolean"
    },
    "furtherDetails.confirmHighRiskItemsAreLessThanHalfInsured": {
        "title": "I confirm that all my high risk items, valued above £4,000 are listed above under personal possessions.",
        "description": "See the [policy wording](attachment:PolicyWording.pdf) for a definition of a high risk item",
        "type": "boolean",
        "enum": [true]
    },
    "moreAbout.title": {
        "type": "string",
        "title": "Title",
        "enum": ["Dr", "Miss", "Mr", "Mrs", "Ms", "Prof"],
        "enumNames": ["Dr", "Miss", "Mr", "Mrs", "Ms", "Prof"]
    },
    "moreAbout.firstName": {
        "type": "string",
        "title": "First Name"
    },
    "moreAbout.lastName": {
        "type": "string",
        "title": "Last Name"
    },
    "moreAbout.primaryPhoneNumber": {
        "type": "string",
        "title": "Mobile Number"
    },
    "moreAbout.contactMethod": {
        "type": "string",
        "title": "How would you like to be contacted",
        "enum": ["email", "phone", "postHomeAddress", "postDifferentAddress"],
        "enumNames": ["Email", "Phone", "Letters to my home address", "Letters to a different address"]
    },
    "moreAbout.marketingApproval": {
        "type": "boolean",
        "title": "I would like to receive promotions, marketing, news, updates and media requests"
    },
    "moreAbout.otherAddress": {
        "type": "object",
        "title": "Mailing Address",
        "required": ["line2", "subzone", "country", "postcode"],
        "properties": {
            "line1": { "title": "Room No, Floor No", "type": "string" },
            "line2": { "title": "Street Name & Number / Building Name", "type": "string" },
            "subzone": { "title": "City", "type": "string" },
            "zone": { "title": "County", "type": "string" },
            "country": { "title": "Country", "type": "string" },
            "postcode": { "title": "Postcode", "type": "string" }
        }
    },
    "declaration.refusedInsurance": {
        "title": "Have you ever been refused insurance?",
        "type": "boolean"
    },
    "declaration.specialTerms": {
        "title": "Have you had special terms applied to an insurance policy?",
        "type": "boolean"
    },
    "declaration.incidentOrLoss": {
        "title": "Have you suffered any incident or loss, insured or uninsured, in the last 3 years in respect of the covers requested in this submission?",
        "type": "boolean"
    },
    "declaration.courtJudgement": {
        "title": "Have you ever been subject to a County Court Judgement served against you or entered into an individual voluntary agreement?",
        "type": "boolean"
    },
    "declaration.bankrupt": {
        "title": "Have you been declared bankrupt or been subject to bankruptcy proceedings?",
        "type": "boolean"
    },
    "declaration.prosecution": {
        "title": "Do you have any unspent criminal convictions or pending prosecutions (other than for minor motoring offences)?",
        "type": "boolean"
    },
    "property.booleans.tenancyAgreementSigned": {
        "title": "You have a signed tenancy agreement in force between you and the landlord, for the property you are renting",
        "type": "boolean"
    },
    "property.booleans.propertyIsOfStandardConstruction": {
        "title": "The property is of standard construction and not more than 20% of the total external roof area either felt covered or flat. Standard construction means the property is built of brick, stone and concrete and roofed with slates, tiles, concrete or metal",
        "type": "boolean"
    },
    "property.booleans.propertyIsNotLocatedNearBodyOfWater": {
        "title": "The property is not located within 250m of any canal, lake, river, sea stream, or any other body of water",
        "type": "boolean"
    },
    "property.booleans.propertyIsInGoodStateOfRepair": {
        "title": "The property is in a good state of repair and free from any damage or defect of any kind",
        "type": "boolean"
    },
    "property.booleans.propertyIsSolelyAPrivateResidence": {
        "title": "The property is occupied solely as a private residence and not used for business purposes other than of a clerical nature",
        "type": "boolean"
    },
    "property.booleans.propertyIsSelfContained": {
        "title": "The property is self contained, having a separate entrance under your sole control",
        "type": "boolean"
    },
    "property.booleans.propertyIsOccupiedByAnAdultDayAndNight": {
        "title": "The property is normally occupied by an adult throughout the day and night, other than working, shopping, visiting and holidays",
        "type": "boolean"
    },
    "property.booleans.propertyIsNotUnoccupiedMoreThan30Days": {
        "title": "The property is not likely to be left unoccupied for more than 30 days at a time",
        "type": "boolean"
    },
    "property.booleans.propertyWindowsHaveLocks": {
        "title": "The property is fitted with key operated or internal locks on accessible windows",
        "type": "boolean"
    },
    "property.booleans.externalDoorsHaveMorticeOrMultipoint": {
        "title": "The property is fitted with external doors protected by either a mortice deadlock or multipoint locking system",
        "type": "boolean"
    },
    "property.terms.agreed": {
        "description": "Please read our [terms of business](https://www.now-insurance.com/terms-of-business) before proceeding.",
        "title": "I have read and agree to the terms of business.",
        "type": "boolean",
        "enum": [true]
    }
}

