import {EPolicyStatus, IAttachment, IClaim, IPolicy, IProduct, PlaceHolder} from "../models";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import {i18n, signInService} from "../services";
import * as BlocksurePolicyUtils from "@blocksure/blocksure-core/dist/src/utilities/PolicyUtils";

export class PolicyUtils {
    static _parseDocumentName = (name: string, version: string) => {
        if (name === "IPID.pdf") {
            return "IPID"; // do not need translate. only for en-GB
        } else if (name === "certificate") {
            return i18n.t("certificate");
        } else if (name === "QuoteSchedule") {
            return i18n.t("quoteSchedule", {version});
        } else if (name === "StatementOfFactTemplate") {
            return i18n.t("statementOfFact", {version});
        } else if (name === "PolicyWording.pdf") {
            return i18n.t("fPolicyWording");
        } else if (name === "PolicyTemplate") {
            return i18n.t("fPolicySchedule", {version});
        } else if (name === "CancellationConfirmation") {
            return i18n.t("fCancellationConfirmation", {version});
        } else if (name === "PolicyQuoteSchedule") {
            return i18n.t("fPolicyQuoteSchedule");
        } else if (name === "ClaimNewTemplate" || name === "ClaimTemplate") {
            return i18n.t("fClaimReport", {version});
        } else {
            return name;
        }
    };

    static getDocumentName = (templateName: string, policy: IPolicy, product: IProduct): string => {
        if (templateName !== "PolicyTemplate") {
            return "";
        } else if (policy.status === EPolicyStatus.Cancelled) {
            return "CancellationConfirmation";
        } else if (policy.status === EPolicyStatus.Quoted) {
            return "PolicyQuoteSchedule";
        } else if (BlocksurePolicyUtils.shouldSkipPayment(signInService.loadAuthDetails(), product)) {
            return "certificate";
        } else {
            return "";
        }
    };

    static getDocuments = (
        policies: { items: IPolicy[]; basepath: string },
        claims: { items: IClaim[]; basepath: string },
        products: { items: IProduct[]; basepath: string },
        appName: string,
        lang: string
    ) => {
        const contentTypeImages = ["image/bmp", "image/gif", "image/jpg", "image/jpeg", "image/png", "image/svg+xml", "image/tiff", "image/webp"];
        const templateNames = ["PolicyTemplate", "StatementOfFactTemplate"];
        let result = [];
        const cacheProduct = {}; // Do not show the same Files for the few Policies

        policies.items.forEach((policy: IPolicy) => {
            const policyProduct = products.items.find((p) => p.id === policy.productId);
            const policyClaims = claims.items.filter((c) => c.policyId === policy.id);
            const templateAttachments = policyProduct.dynamicDocumentTemplates.filter((attachment) => templateNames.includes(attachment.name));

            // display only one Claim Template
            if (policyClaims.length) {
                const claimTemplate = policyProduct.dynamicDocumentTemplates.find((item) => item.name === "ClaimNewTemplate" || item.name === "ClaimTemplate");

                if (claimTemplate) {
                    templateAttachments.push(claimTemplate);
                }
            }

            const templates = [];
            templateAttachments.forEach((attachment: IAttachment) => {
                if (attachment.name === "ClaimTemplate" || attachment.name === "ClaimNewTemplate") {
                    policyClaims.forEach((claim) => {
                        templates.push({
                            ...attachment,
                            attachment,
                            modifiedDate: claim.creationDate,
                            externalRefPath: `/claims/${claim.id}`,
                            externalRefLabel: claim.reportingRef,
                            url: `${claims.basepath}${claim.id}?format=PDF&app-name=${appName}&lng=${lang}`,
                            version: `v${policy.version}.${policy.sequence}`
                        });
                    });
                } else {
                    templates.push({
                        ...attachment,
                        attachment,
                        modifiedDate: policy.issueDate || policy.inceptionDate,
                        name: PolicyUtils.getDocumentName(attachment.name, policy, policyProduct) || attachment.name,
                        externalRefPath: `/policies/${policy.id}/${policy.sequenceId}`,
                        externalRefLabel: policy.reportingRef,
                        url: PolicyUtils.formatAttachmentHref(attachment, policies.basepath, policy.sequenceId, appName, lang),
                        version: `v${policy.version}.${policy.sequence}`
                    });
                }
            });

            if (templates && templates.length) {
                result = [...result, ...templates];
            }

            // Skip proccessed Product
            if (!cacheProduct[policyProduct.id]) {
                cacheProduct[policyProduct.id] = true;

                const files = policyProduct.staticAttachments
                    .filter((attachment) => attachment.contentType === "application/pdf")
                    .map((attachment: IAttachment) => ({
                        ...attachment,
                        attachment,
                        modifiedDate: attachment.modifiedDate,
                        externalRefPath: `/surebyld/${policyProduct.id}`,
                        externalRefLabel: policyProduct.id,
                        url: `${products.basepath}${policyProduct.id}/${attachment.location}?app-name=${appName}&lng=${lang}`,
                        version: ""
                    }));

                if (files && files.length) {
                    result = [...result, ...files];
                }
            }

            policy.attachments
                .filter((attachment) => contentTypeImages.includes(attachment.contentType))
                .forEach((item) =>
                    result.push({
                        ...item,
                        url: `${policies.basepath}${policy.sequenceId}/${item.location}/?app-name=${appName}`,
                        modifiedDate: item.modifiedDate || policy.effectiveFromDate,
                        name: item.description,
                        version: `v${policy.version}.${policy.sequence}`
                    })
                );
        });

        return result;
    };

    static getStatus(value: EPolicyStatus) {
        const status = value.toLowerCase();
        switch (value.toLowerCase()) {
            case "ntu":
                return i18n.t("lapsedQuote");
            default:
                return i18n.t(status);
        }
    }

    static getExpiryDateTime(data: IPolicy) {
        const status = data && data.status;
        if (status !== null) {
            const expiry = status === EPolicyStatus.Quoted ? "quoteExpiryDate" : "expiryDate";
            return data[expiry];
        }
    }

    static _getTotalDeduction(data: IPolicy) {
        return data && Math.round(data.grossPremium.delta * 100 - this.getValueByKey(data.totals, "Net Premium", "delta") * 100) / 100;
    }

    static getSplitPercentage = (product, split) => {
        return product.payment && product.payment.premiumSplits && product.payment.premiumSplits[split]
            ? product.payment.premiumSplits[split].amount
            : 0;
    };

    static _getTotalDeductionsPercent(data: IPolicy) {
        return data && this.getValueByKey(data.totals, "Net Premium", "delta")
            ? 100 - Math.round(((this.getValueByKey(data.totals, "Net Premium", "delta") * 100) / (data.grossPremium.delta * 100)) * 100)
            : 0;
    }

    static _getNetPremiumLessInsurerPlatformFeePlusIpt(data: IPolicy) {
        const netPremium = this.getValueByKey(data.totals, "Net Premium", "delta");
        const insurerPlatformFee = this.getValueByKey(data.totals, "Insurer Platform Fee", "delta");
        const ipt = this.getValueByKey(data.totals, "IPT", "delta");
        return data ? (netPremium * 100 - insurerPlatformFee * 100 + ipt * 100) / 100 : 0;
    }

    static getValueByKey = (data, name, key) => {
        return (data && data[name] && data[name][key]) || 0;
    };

    static _formatParty = (party: string) => {
        if (!party) return PlaceHolder;
        const tokens = party.split(/[,=]/);
        return tokens[tokens.indexOf("O") + 1];
    };

    static formatAttachmentHref = (attachment: IAttachment, basepath: string, policyId: string, appName: string, lang: string) => {
        if (attachment.url) {
            return attachment.url;
        } else if (attachment.name === "PolicyTemplate" || attachment.name === "CancellationConfirmation") {
            return `${basepath}${policyId}?format=PDF&app-name=${appName}&lng=${lang}`;
        } else if (attachment.name === "StatementOfFactTemplate") {
            return `${basepath}${policyId}?format=PDF&docType=StatementOfFactTemplate&app-name=${appName}&lng=${lang}`;
        } else {
            // PDF files from Product 'IPID.pdf', 'PolicyWording.pdf'
            return `${basepath}${policyId}/${attachment.location}?app-name=${appName}&lng=${lang}`;
        }
    };

    static parseIsoDuration = (frequency) => {
        const duration = FormatUtils.parseIsoDuration(frequency);

        if (duration.years > 0) return "day";
        if (duration.months > 0) return "day";
        if (duration.days > 0) return "hour";
        if (duration.hours > 0) return "minute";
        else return "minute";
    };

    static _getTransactionAttempt = (transaction, name) => {
        if (!transaction) return "-";
        const length = transaction.attempts.length;
        const reference = transaction.attempts[length - 1] && transaction.attempts[length - 1][name];
        if (!reference) return "-";
        return reference;
    };
}
