import React, { memo } from "react";
import { FormControl, FormHelperText, InputLabel, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createGenerateClassName, StylesProvider, Theme, ThemeProvider } from "@material-ui/core/styles";
import "./styles.css";
import InputCustom from "../InputCustom";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "autocompletecustom",
});

interface ISelectOption {
    disabled?: boolean;
    label: string;
    value: string;
}

interface IProps {
    disabled?: boolean;
    error?: string;
    freeSolo?: boolean;
    fullWidth?: boolean;
    getLabel?: boolean;
    helperText?: string;
    inputValue?: string;
    label?: string;
    name: string;
    options: ISelectOption[];
    readOnly?: boolean;
    theme: Theme;
    touched?: boolean;
    value?: ISelectOption;
    onChange?: (e: React.ChangeEvent<any>, value: string) => void;
    onKeyPress?: (e: React.ChangeEvent<any>) => void;
    onInput?: (e: React.ChangeEvent<any>) => void;
}

const AutocompleteCustom: React.FC<IProps> = ({
    disabled,
    error,
    freeSolo,
    fullWidth = false,
    getLabel = true,
    helperText,
    inputValue,
    label,
    name,
    options,
    readOnly,
    theme,
    touched,
    value,
    onChange,
    onInput,
    onKeyPress,
}) => {
    if (readOnly) {
        return <InputCustom label={label} readOnly={true} theme={theme} value={value?.label} />;
    }
    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <InputLabel htmlFor={name} color="secondary">
                    {label}
                </InputLabel>
                <FormControl error={touched && Boolean(error)} fullWidth={fullWidth} variant="outlined">
                    <Autocomplete
                        disableClearable
                        disabled={disabled}
                        freeSolo={freeSolo}
                        fullWidth={fullWidth}
                        options={options}
                        value={value}
                        inputValue={inputValue}
                        getOptionDisabled={(option) => option.disabled === true}
                        getOptionLabel={(option) => (typeof option !== "string" ? option?.label : option)}
                        onChange={(e, newValue) => onChange(e, typeof newValue === "string" ? newValue : getLabel ? newValue?.label : newValue.value)}
                        onInputCapture={onInput}
                        renderInput={(params) => <TextField {...params} name={name} variant="outlined" onKeyPress={onKeyPress} />}
                    />
                    <FormHelperText>{touched ? helperText || error : ""}</FormHelperText>
                </FormControl>
            </ThemeProvider>
        </StylesProvider>
    );
};

export default memo(AutocompleteCustom);