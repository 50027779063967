import * as React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { Box, CircularProgress } from "@material-ui/core";
import { createGenerateClassName, StylesProvider, Theme, ThemeProvider } from "@material-ui/core/styles";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "loadingbtn",
});

interface ILoadingBtn {
    theme: Theme;
    fetching: boolean;
    label: string;
    type?: string;
    disabled?: boolean;
    onClick?: () => void;
    fullWidth?: boolean;
    className?: string;
}

const LoadingBtn: React.FC<ButtonProps & ILoadingBtn> = ({ theme, fetching, label, fullWidth, className, disabled, ...rest }) => {
    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <Button fullWidth={fullWidth} className={className} disabled={disabled || fetching} {...rest}>
                    {label}
                    {fetching ? (
                        <Box ml={1}>
                            <CircularProgress size={16} style={{ verticalAlign: "middle" }} />
                        </Box>
                    ) : null}
                </Button>
            </ThemeProvider>
        </StylesProvider>
    );
};

export default LoadingBtn;
