export enum ELocale {
    en = "en",
    enGB = "en-GB",
    enUS = "en-US", // Safari and Edge browser default value
    jaJP = "ja-JP",
    idID = "id-ID",
}

export function isEnglish(myLocale: string): boolean {
    return [ELocale.en, ELocale.enGB, ELocale.enUS].map((it) => it.toLowerCase()).includes(myLocale.toLowerCase());
}

export const generateLanguageOptions = (t) => ([
    { label: t("english"), value: ELocale.en },
    { label: t("japanese"), value: ELocale.jaJP },
    { label: t("indonesian"), value: ELocale.idID },
]);
