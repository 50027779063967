import React from "react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { createGenerateClassName, StylesProvider, Theme, ThemeProvider } from "@material-ui/core";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "records-not-found",
});

interface IProps {
    theme: Theme;
}

export const RecordsNotFound: React.FC<IProps> = ({ theme }) => {
    const { t } = useTranslation();

    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <Typography align="center" variant="body2">
                    [{t("recordsNotFound")}]
                </Typography>
            </ThemeProvider>
        </StylesProvider>
    );
};
