import i18next from "i18next";
import Backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";
import {AssetsService} from "./assets.service";
import {localeService} from "./locale.service";

i18next.use(Backend).use(initReactI18next);

export function initI18(lang) {
    return i18next.init(
        {
            backend: {
                loadPath: `${AssetsService.basePath()}translations/translation-{{ns}}-{{lng}}.json`,
                crossDomain: true,
            },
            lng: lang,
            ns: ["surelync"],
            defaultNS: "surelync",
            fallbackLng: false,
            interpolation: {
                escapeValue: false,
            },
            react: {
                useSuspense: false,
            },
            saveMissing: true,
            missingKeyHandler,
        },
        (err) => {
            if (err) {
                console.error("something went wrong loading and parse", err);
                localeService.setLocale(localeService.default);
            }
        }
    );
}

function missingKeyHandler(locale, a, key) {
    return undefined
}

export const i18n = i18next;
