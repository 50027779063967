export interface IAttachment {
    blobHash?: string;
    contentType: string;
    description: string;
    location?: string;
    modifiedDate?: string;
    name: string;
    product?: boolean;
    transactions: EAttachmentTransaction[];
    url?: string;
    version?: string;
}

export enum EAttachmentTransaction {
    Register = "Register",
    Quote = "Quote",
    Bind = "Bind",
    MTA = "MTA",
    Cancellation = "Cancellation",
    Decline = "Decline",
    RenewalInvite = "RenewalInvite",
    PreRenewalReminder = "PreRenewalReminder",
    LapseWarning = "LapseWarning",
    Lapse = "Lapse",
    Renewal = "Renewal",
    Claim = "Claim",
    Invoice = "Invoice",
    InvoiceWarning = "InvoiceWarning",
    Payment = "Payment",
}

export interface INewFile {
    file: File;
    fromSystem?: boolean;
    location: string;
    name?: string;
    type: string;
    url: string;
}
