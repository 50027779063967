import React, { useEffect, useState } from "react";
import { TableCell, TableRow, TableSortLabel } from "@material-ui/core";
import { useStyles } from "./styles";
import { createGenerateClassName, StylesProvider, Theme, ThemeProvider } from "@material-ui/core/styles";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "headerrow",
});

interface IProps {
    headerGroup: any;
    headers: any[];
    isCollapseTable?: boolean;
    isRefreshInterval?: boolean;
    theme: Theme;
    onSort?: (id: string, isSorted: boolean, isSortedDesc: boolean) => void;
}

const HeaderRow: React.FC<IProps> = ({ headerGroup, headers, isCollapseTable, isRefreshInterval, theme, onSort }) => {
    const classes = useStyles(theme)();
    const [sort, setSort] = useState<{ id: string; isSorted: boolean; isSortedDesc: boolean }>(null);
    const [savedHeaderGroup, setSavedHeaderGroup] = useState(null);

    // Refresh Interval brake sorting
    // save sorting and apply for the updated data
    const handleHeaderClick = (handleColumnClick, column) => (e) => {
        handleColumnClick(e);

        setTimeout(() => {
            const { id, isSorted, isSortedDesc } = column;
            setSort({ id, isSorted, isSortedDesc });
            onSort(id, isSorted, isSortedDesc);
        }, 0);
    };

    // apply saved sort settings
    useEffect(() => {
        if (sort) {
            const header = headerGroup.headers.find((h) => h.id === sort.id);
            header.isSorted = sort.isSorted;
            header.isSortedDesc = sort.isSortedDesc;
            header.toggleSortBy(sort.isSortedDesc);
        }

        setSavedHeaderGroup({ ...headerGroup });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headerGroup]);

    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <TableRow {...(savedHeaderGroup ? savedHeaderGroup.getHeaderGroupProps() : headerGroup.getHeaderGroupProps())}>
                    {isCollapseTable && <TableCell className={classes.extrsSmall} />}
                    {headers.map((column) => {
                        const colHeaderProps = column.getHeaderProps(column.getSortByToggleProps());
                        return (
                            <TableCell
                                {...colHeaderProps}
                                align="left"
                                className={classes[column.size]}
                                onClick={isRefreshInterval ? handleHeaderClick(colHeaderProps.onClick, column) : colHeaderProps.onClick}
                            >
                                {!column.disableSortBy ? (
                                    <TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? "desc" : "asc"}>
                                        {column.render("Header")}
                                        {column.isSorted ? (
                                            <span className={classes.visuallyHidden}>
                                                {column.isSortedDesc ? "sorted descending" : "sorted ascending"}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                ) : (
                                    <div>{column.render("Header")}</div>
                                )}
                            </TableCell>
                        );
                    })}
                </TableRow>
            </ThemeProvider>
        </StylesProvider>
    );
};

export default HeaderRow;
