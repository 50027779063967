import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { createGenerateClassName, StylesProvider, Theme, ThemeProvider } from "@material-ui/core/styles";
import { IError } from "./models";
import IconField from "./IconField";

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync-common",
    seed: "password-validation",
});

interface IProps {
    errors: IError;
    theme: Theme;
}

const ValidationLabel: React.FC<IProps> = ({ errors, theme }) => {
    const { t } = useTranslation();

    const tenCharacters = `${t("pwdMin", { minChars: 10 })}`;
    const lessThan50Characters = t("pwdMax", { maxChars: 50 });
    const oneUppercaseCharacter = `${t("pwdUpper", { minChars: 1 })}`;
    const oneLowercaseCharacter = `${t("pwdLower", { minChars: 1 })}`;
    const oneNumber = `${t("pwdNumber", { minChars: 1 })}`;

    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
                <div>
                    <Typography variant="body1" gutterBottom>
                        {t("passwordMustHaveAtLeast")}:
                    </Typography>
                    {errors && !errors.long && <IconField theme={theme} text={tenCharacters} error={errors.short} />}
                    {errors && errors.long && <IconField theme={theme} text={lessThan50Characters} error={errors.long} />}
                    {errors && <IconField theme={theme} text={oneUppercaseCharacter} error={errors.capital} />}
                    {errors && <IconField theme={theme} text={oneLowercaseCharacter} error={errors.lower} />}
                    {errors && <IconField theme={theme} text={oneNumber} error={errors.number} />}
                </div>
            </ThemeProvider>
        </StylesProvider>
    );
};

export default ValidationLabel;
